import React, { Fragment, useEffect, useState } from "react";

//Ajax Methods
import ajaxMethods from "../../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../../api/ajax-endpoints";

//redux
import { setToast } from "../../../redux/actions/toast";
import { useDispatch } from "react-redux";

//country code json
import { countryCode } from "../../../models/product-data-model";
import { Loader } from "../../../UI/Loaders/Loaders";
import { ToastSlim } from "../../../UI/Toast/Toast";
import { appInsights } from "../../../config/appInsights";
import { MixPanel } from "../../../shared/MixPanel/MixPanel";

const AggregatorProfileSettings = (props: any) => {
  const [state, setState] = useState({
    profileEdit: false,
    loading: false,
    error: false,
    //sector
    sectorCategoryOption: [],
    selectedSectorId: "",
    first_name: "",
    last_name: "",
    emailAddress: "",
    biz_name: "",
    biz_email: "",
    biz_phone: "",
    biz_address: "",
    aggregator_id: props.aggregator_id,
    aggregator_domain: "",
    short_name: "",
    code: "",
    allCountryCodes: [],
    widgetEditValue: "",
    bulkLoanApplicationValue: "",
    allowBvnValidation: "",
    allowAccountNumberValidation: "",
    maximumRunningLoan: "",
    aggregatorType: "",
    availableTypes: [],
    isUpdating: false,
    toastMessage: "",
    toastType: "",
    showToast: false,
    title: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    getAggregatorDrilldown(props.aggregator_id);
    getSectorCategories();
    getAllCountryCode();
    getAggregatorTypes();
  }, []);

  useEffect(() => {
    // prettier-ignore
    MixPanel.track_pageview({ "page": "Aggregator Profile Settings" });
  }, []);

  useEffect(() => {
    if (props.aggregator_id) {
      getAggregatorDrilldown(props.aggregator_id);
    }
  }, [props.aggregator_id]);

  const getAggregatorDrilldown = (id: number) => {
    setState((prev) => {
      return {
        ...prev,
        loading: true,
        error: false,
      };
    });
    ajaxMethods
      .get(ajaxEndpoints.GET_AGG_DRILLDOWN + `?aggregator_id=${id}`)
      .then((response) => {
        setState((prev) => {
          return { ...prev, loading: false, error: false };
        });
        if (response) {
          let res = response.data;
          let info = response.data.aggregator_rep;

          if (res.xaggregator_domain && res.short_name && res.country_code) {
            setState((prev) => {
              return {
                ...prev,
                aggregator_id: res.aggregator_id,
                first_name: info.first_name,
                last_name: info.last_name,
                emailAddress: info.personal_email,
                biz_name: res.biz_name,
                biz_email: res.biz_email,
                biz_phone: res.biz_phone,
                biz_address: res.biz_Address,
                selectedSectorId: res.biz_category,
                aggregator_domain: res.xaggregator_domain,
                short_name: res.short_name,
                code: res.country_code,
                widgetEditValue: res.allow_widget_edit,
                bulkLoanApplicationValue:
                  res.allow_bulk_loan_application_via_ui,
                allowAccountNumberValidation: res.allow_bank_account_validation,
                allowBvnValidation: res.allow_bvn_validation,
                maximumRunningLoan: res.maximum_running_loan,
                aggregatorType: res.aggregator_type,
                title: info?.title,
              };
            });
          } else if (
            res.xaggregator_domain === null ||
            res.short_name === null ||
            res.country_code === null
          ) {
            if (
              res.xaggregator_domain === null &&
              res.short_name !== null &&
              res.country_code !== null
            ) {
              setState((prev) => {
                return {
                  ...prev,
                  aggregator_id: res.aggregator_id,
                  first_name: info.first_name,
                  last_name: info.last_name,
                  emailAddress: info.personal_email,
                  biz_name: res.biz_name,
                  biz_email: res.biz_email,
                  biz_phone: res.biz_phone,
                  biz_address: res.biz_Address,
                  selectedSectorId: res.biz_category,
                  aggregator_domain: "",
                  short_name: res.short_name,
                  code: res.country_code,
                  widgetEditValue: res.allow_widget_edit,
                  bulkLoanApplicationValue:
                    res.allow_bulk_loan_application_via_ui,
                  allowAccountNumberValidation:
                    res.allow_bank_account_validation,
                  allowBvnValidation: res.allow_bvn_validation,
                  maximumRunningLoan: res.maximum_running_loan,
                  aggregatorType: res.aggregator_type,
                  title: info?.title,
                };
              });
            } else if (
              res.xaggregator_domain !== null &&
              res.short_name === null &&
              res.country_code !== null
            ) {
              setState((prev) => {
                return {
                  ...prev,
                  aggregator_id: res.aggregator_id,
                  first_name: info.first_name,
                  last_name: info.last_name,
                  emailAddress: info.personal_email,
                  biz_name: res.biz_name,
                  biz_email: res.biz_email,
                  biz_phone: res.biz_phone,
                  biz_address: res.biz_Address,
                  selectedSectorId: res.biz_category,
                  aggregator_domain: res.xaggregator_domain,
                  short_name: "",
                  code: res.country_code,
                  widgetEditValue: res.allow_widget_edit,
                  bulkLoanApplicationValue:
                    res.allow_bulk_loan_application_via_ui,
                  allowAccountNumberValidation:
                    res.allow_bank_account_validation,
                  allowBvnValidation: res.allow_bvn_validation,
                  maximumRunningLoan: res.maximum_running_loan,
                  aggregatorType: res.aggregator_type,
                  title: info?.title,
                };
              });
            } else if (
              res.xaggregator_domain !== null &&
              res.short_name !== null &&
              res.country_code === null
            ) {
              setState((prev) => {
                return {
                  ...prev,
                  aggregator_id: res.aggregator_id,
                  first_name: info.first_name,
                  last_name: info.last_name,
                  emailAddress: info.personal_email,
                  biz_name: res.biz_name,
                  biz_email: res.biz_email,
                  biz_phone: res.biz_phone,
                  biz_address: res.biz_Address,
                  selectedSectorId: res.biz_category,
                  aggregator_domain: res.xaggregator_domain,
                  short_name: res.short_name,
                  code: "",
                  widgetEditValue: res.allow_widget_edit,
                  bulkLoanApplicationValue:
                    res.allow_bulk_loan_application_via_ui,
                  allowAccountNumberValidation:
                    res.allow_bank_account_validation,
                  allowBvnValidation: res.allow_bvn_validation,
                  maximumRunningLoan: res.maximum_running_loan,
                  aggregatorType: res.aggregator_type,
                  title: info?.title,
                };
              });
            } else if (
              res.xaggregator_domain === null &&
              res.short_name === null &&
              res.country_code !== null
            ) {
              setState((prev) => {
                return {
                  ...prev,
                  aggregator_id: res.aggregator_id,
                  first_name: info.first_name,
                  last_name: info.last_name,
                  emailAddress: info.personal_email,
                  biz_name: res.biz_name,
                  biz_email: res.biz_email,
                  biz_phone: res.biz_phone,
                  biz_address: res.biz_Address,
                  selectedSectorId: res.biz_category,
                  aggregator_domain: "",
                  short_name: "",
                  code: res.country_code,
                  widgetEditValue: res.allow_widget_edit,
                  bulkLoanApplicationValue:
                    res.allow_bulk_loan_application_via_ui,
                  allowAccountNumberValidation:
                    res.allow_bank_account_validation,
                  allowBvnValidation: res.allow_bvn_validation,
                  maximumRunningLoan: res.maximum_running_loan,
                  aggregatorType: res.aggregator_type,
                  title: info?.title,
                };
              });
            } else if (
              res.xaggregator_domain === null &&
              res.short_name !== null &&
              res.country_code === null
            ) {
              setState((prev) => {
                return {
                  ...prev,
                  aggregator_id: res.aggregator_id,
                  first_name: info.first_name,
                  last_name: info.last_name,
                  emailAddress: info.personal_email,
                  biz_name: res.biz_name,
                  biz_email: res.biz_email,
                  biz_phone: res.biz_phone,
                  biz_address: res.biz_Address,
                  selectedSectorId: res.biz_category,
                  aggregator_domain: "",
                  short_name: res.short_name,
                  code: "",
                  widgetEditValue: res.allow_widget_edit,
                  bulkLoanApplicationValue:
                    res.allow_bulk_loan_application_via_ui,
                  allowAccountNumberValidation:
                    res.allow_bank_account_validation,
                  allowBvnValidation: res.allow_bvn_validation,
                  maximumRunningLoan: res.maximum_running_loan,
                  aggregatorType: res.aggregator_type,
                  title: info?.title,
                };
              });
            } else if (
              res.xaggregator_domain !== null &&
              res.short_name === null &&
              res.country_code === null
            ) {
              setState((prev) => {
                return {
                  ...prev,
                  aggregator_id: res.aggregator_id,
                  first_name: info.first_name,
                  last_name: info.last_name,
                  emailAddress: info.personal_email,
                  biz_name: res.biz_name,
                  biz_email: res.biz_email,
                  biz_phone: res.biz_phone,
                  biz_address: res.biz_Address,
                  selectedSectorId: res.biz_category,
                  aggregator_domain: res.xaggregator_domain,
                  short_name: "",
                  code: "",
                  widgetEditValue: res.allow_widget_edit,
                  bulkLoanApplicationValue:
                    res.allow_bulk_loan_application_via_ui,
                  allowAccountNumberValidation:
                    res.allow_bank_account_validation,
                  allowBvnValidation: res.allow_bvn_validation,
                  maximumRunningLoan: res.maximum_running_loan,
                  aggregatorType: res.aggregator_type,
                  title: info?.title,
                };
              });
            } else if (
              res.xaggregator_domain === null &&
              res.short_name === null &&
              res.country_code === null
            ) {
              setState((prev) => {
                return {
                  ...prev,
                  aggregator_id: res.aggregator_id,
                  first_name: info.first_name,
                  last_name: info.last_name,
                  emailAddress: info.personal_email,
                  biz_name: res.biz_name,
                  biz_email: res.biz_email,
                  biz_phone: res.biz_phone,
                  biz_address: res.biz_Address,
                  selectedSectorId: res.biz_category,
                  aggregator_domain: "",
                  short_name: "",
                  code: "",
                  widgetEditValue: res.allow_widget_edit,
                  bulkLoanApplicationValue:
                    res.allow_bulk_loan_application_via_ui,
                  allowAccountNumberValidation:
                    res.allow_bank_account_validation,
                  allowBvnValidation: res.allow_bvn_validation,
                  maximumRunningLoan: res.maximum_running_loan,
                  aggregatorType: res.aggregator_type,
                  title: info?.title,
                };
              });
            }
          } else {
            setState((prev) => {
              return {
                ...prev,
                aggregator_id: res.aggregator_id,
                first_name: info.first_name,
                last_name: info.last_name,
                emailAddress: info.personal_email,
                biz_name: res.biz_name,
                biz_email: res.biz_email,
                biz_phone: res.biz_phone,
                biz_address: res.biz_Address,
                selectedSectorId: res.biz_category,
                aggregator_domain: res.xaggregator_domain,
                short_name: res.short_name,
                code: res.country_code,
                widgetEditValue: res.allow_widget_edit,
                bulkLoanApplicationValue:
                  res.allow_bulk_loan_application_via_ui,
                allowAccountNumberValidation: res.allow_bank_account_validation,
                allowBvnValidation: res.allow_bvn_validation,
                maximumRunningLoan: res.maximum_running_loan,
                aggregatorType: res.aggregator_type,
                title: info?.title,
              };
            });
          }
        }
      })
      .catch((error: any) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProfileSettings.js",
          },
        });
        setState((prev) => {
          return { ...prev, loading: false, error: true };
        });
      });
  };

  const getAggregatorTypes = () => {
    ajaxMethods
      .get(ajaxEndpoints.AGGREGATOR_TYPES)
      .then((response) => {
        if (response?.status === true) {
          const availableAggregatorTypes = response?.data.map(
            (aggregatorType: any, index: number) => {
              return (
                <option key={index} value={aggregatorType?.name}>
                  {aggregatorType?.name}
                </option>
              );
            },
          );

          return setState((prev) => {
            return { ...prev, availableTypes: availableAggregatorTypes };
          });
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProfileSettings.js",
          },
        });
      });
  };

  const editModeHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    setState((prev) => {
      return {
        ...prev,
        profileEdit: true,
      };
    });
  };

  const cancelUpdateHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    getAggregatorDrilldown(state.aggregator_id);
    setState((prev) => {
      return {
        ...prev,
        profileEdit: false,
        isUpdating: false,
      };
    });
  };

  const handleOptionSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;

    setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const onProfileUpdate = (e: React.MouseEvent) => {
    e.preventDefault();
    setState((prev) => {
      return { ...prev, isUpdating: true };
    });

    const {
      selectedSectorId,
      first_name,
      last_name,
      emailAddress,
      biz_name,
      biz_email,
      biz_phone,
      biz_address,
      aggregator_id,
      aggregator_domain,
      short_name,
      code,
      widgetEditValue,
      bulkLoanApplicationValue,
      allowAccountNumberValidation,
      allowBvnValidation,
      maximumRunningLoan,
      aggregatorType,
      title,
    } = state;
    let newWidgetEditValue;

    if (widgetEditValue === "true") {
      newWidgetEditValue = true;
    } else if (widgetEditValue === "false") {
      newWidgetEditValue = false;
    } else {
      newWidgetEditValue = JSON.parse(widgetEditValue);
    }

    const responseParams = {
      biz_category: selectedSectorId,
      first_name,
      last_name,
      personal_email: emailAddress,
      biz_name,
      biz_email,
      biz_phone,
      biz_address,
      aggregator_id,
      aggregator_domain,
      short_name,
      country_code: code,
      allow_widget_edit: JSON.parse(newWidgetEditValue),
      allow_bulk_loan_application_via_ui:
        Number(bulkLoanApplicationValue) === 1 ? true : false,
      allow_bank_account_validation: JSON.parse(allowAccountNumberValidation), // Convert to boolean if it is a string
      allow_bvn_validation: JSON.parse(allowBvnValidation), // Convert tp boolean if it is a string
      maximum_running_loan: Number(maximumRunningLoan),
      aggregator_type: aggregatorType,
      title,
    };

    ajaxMethods
      .post(ajaxEndpoints.UPDATE_AGGREGATOR_PROFILE, responseParams)
      .then((response) => {
        setState((prev) => {
          return { ...prev, isUpdating: false };
        });
        if (response.status === 200) {
          setState((prev) => {
            return { ...prev, profileEdit: false };
          });
          return dispatch(setToast("success", response.data.message));
        } else if (response.status === 400) {
          setState((prev) => {
            return {
              ...prev,
              toastMessage: `There was an issue updating profile settings: ${response.data.message}`,
              toastType: "error",
              showToast: true,
            };
          });
        } else {
          setState((prev) => {
            return {
              ...prev,
              toastMessage: "There was an issue updating profile setting",
              toastType: "warning",
              showToast: true,
            };
          });
        }
      })
      .catch((error: any) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProfileSettings.js",
          },
        });

        setState((prev) => {
          return {
            ...prev,
            toastMessage: "There was an issue updating profile setting",
            toastType: "error",
            showToast: true,
            isUpdating: false,
          };
        });
      });
  };
  //get category sectors
  const getSectorCategories = () => {
    ajaxMethods
      .get(ajaxEndpoints.CATEGORY_LIST)
      .then((response) => {
        let categoryList = [];

        if (response.data.length > 0) {
          categoryList = [...response.data];
        } else {
          categoryList = [
            {
              category_id: 0,
              category_name: "No Categories Yet",
              category_slug: "No Categories Yet",
            },
          ];
        }
        const sectorCategoryOptions = categoryList.map((ecosystem, index) => {
          const { category_id, category_name } = ecosystem;
          return (
            <option key={index} id={category_id} value={category_name}>
              {category_name}
            </option>
          );
        });
        setState((prev: any) => {
          return {
            ...prev,
            sectorCategoryOption: sectorCategoryOptions,
          };
        });
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProfileSettings.js",
          },
        });
      });
  };
  // get all imported country code
  const getAllCountryCode = () => {
    const allCountryCodes = countryCode.map((ecosystem, index) => {
      const { code, name } = ecosystem;
      return (
        <option key={index} id={code} value={code}>
          {name}
        </option>
      );
    });
    setState((prev: any) => {
      return {
        ...prev,
        allCountryCodes: allCountryCodes,
      };
    });
  };

  const {
    profileEdit,
    loading,
    error,
    sectorCategoryOption,
    selectedSectorId,
    first_name,
    last_name,
    emailAddress,
    biz_name,
    biz_email,
    biz_phone,
    biz_address,
    aggregator_domain,
    short_name,
    code,
    allCountryCodes,
    widgetEditValue,
    bulkLoanApplicationValue,
    allowAccountNumberValidation,
    allowBvnValidation,
    maximumRunningLoan,
    aggregatorType,
    availableTypes,
    isUpdating,
    toastMessage,
    toastType,
    showToast,
    title,
  } = state;

  return (
    <div className="animated mt-30">
      <div className="col-lg-3 col-md-3 col-sm-12"></div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <div className="card">
          <div className="body text-center">
            <h3 className="m-t-0">
              <i className="adv-icon fas fa-user-circle" /> Aggregator Profile
            </h3>
            <div>
              {loading ? (
                <Loader
                  centered={true}
                  text="Loading aggregator profile..."
                  background={true}
                />
              ) : error ? (
                <div className="grid__padding animated fadeInRight mt-50">
                  <h4 className="center-txt p-y-5 mt-50">
                    <i className="fas fa-bell" /> There was an error while
                    updating aggregator profile, Please reload this page
                  </h4>
                </div>
              ) : (
                <Fragment>
                  {/*Display Potential Borrower's Credentials from Aggregator */}
                  <div id="adv-borrower">
                    <div className="row m-t-1">
                      <div className="col-xs-6">
                        <label className="label-txt">First Name</label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="text"
                            className="user__form form-control"
                            data-form="profile"
                            placeholder="First Name"
                            readOnly={!profileEdit}
                            name="first_name"
                            value={first_name}
                            onChange={inputChangeHandler}
                          />
                          <i className="fas fa-user-circle" />
                        </div>
                      </div>

                      <div className="col-xs-6">
                        <label className="label-txt">Last Name</label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="text"
                            className="user__form form-control"
                            data-form="profile"
                            placeholder="Last Name"
                            readOnly={!profileEdit}
                            name="last_name"
                            value={last_name}
                            onChange={inputChangeHandler}
                          />
                          <i className="fas fa-user-circle" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-6">
                        <label className="label-txt">Sector</label>
                        <div className="form-group inputWithIcon">
                          <select
                            className="round user__form form-control form-load"
                            name="selectedSectorId"
                            placeholder="Select a Sector"
                            title="Select a Sector"
                            id="selectedSectorId"
                            disabled={!profileEdit}
                            value={selectedSectorId}
                            onChange={handleOptionSelect}
                          >
                            {/* <option selected>Select a Sector</option> */}
                            {sectorCategoryOption}
                          </select>
                          <i className="fas fa-building"></i>
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <label className="label-txt">Email</label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="text"
                            className="user__form form-control"
                            onChange={inputChangeHandler}
                            placeholder="Email"
                            data-form="profile"
                            readOnly
                            name="emailAddress"
                            value={emailAddress}
                          />
                          <i className="fas fa-map-marked-alt" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-6">
                        <label className="label-txt">Business Name</label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="text"
                            className="user__form form-control"
                            onChange={inputChangeHandler}
                            placeholder="Business Name"
                            data-form="profile"
                            readOnly={!profileEdit}
                            name="biz_name"
                            value={biz_name}
                          />
                          <i className="fas fa-user-circle" />
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <label className="label-txt">Business Email</label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="text"
                            className="user__form form-control"
                            onChange={inputChangeHandler}
                            placeholder="Business Email"
                            data-form="profile"
                            readOnly={!profileEdit}
                            name="biz_email"
                            value={biz_email}
                          />
                          <i className="fas fa-map-marked-alt" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-6">
                        <label className="label-txt">
                          Business Phone Number
                        </label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="tel"
                            className="user__form form-control"
                            placeholder="Business Phone Number"
                            readOnly={!profileEdit}
                            data-form="profile"
                            name="biz_phone"
                            onChange={inputChangeHandler}
                            value={biz_phone}
                          />
                          <i className="fas fa-mobile" />
                        </div>
                      </div>

                      <div className="col-xs-6">
                        <label className="label-txt">Business Address</label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="text"
                            className="user__form form-control"
                            onChange={inputChangeHandler}
                            placeholder="Business Address"
                            data-form="profile"
                            readOnly={!profileEdit}
                            name="biz_address"
                            value={biz_address}
                          />
                          <i className="fas fa-address-card" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-6">
                        <label className="label-txt">Domain</label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="text"
                            className="user__form form-control"
                            onChange={inputChangeHandler}
                            placeholder="Domain"
                            data-form="profile"
                            readOnly={!profileEdit}
                            name="aggregator_domain"
                            value={aggregator_domain}
                          />
                          <i className="fas fa-address-card" />
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <label className="label-txt">
                          Aggregator Short Name
                        </label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="text"
                            className="user__form form-control"
                            onChange={inputChangeHandler}
                            placeholder="short name"
                            data-form="profile"
                            readOnly={!profileEdit}
                            name="short_name"
                            value={short_name}
                          />
                          <i className="fas fa-address-card" />
                        </div>
                      </div>
                    </div>
                    <div className="row m-b-1">
                      <div className="col-xs-6">
                        <label className="label-txt">Country Code</label>
                        <div className="form-group">
                          <select
                            className="round user__form form-control form-load"
                            disabled={!profileEdit}
                            name="code"
                            value={code}
                            onChange={handleOptionSelect}
                          >
                            <option selected></option>
                            {allCountryCodes}
                          </select>
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <label className="label-txt">Allow Widget Edit</label>
                        <div className="form-group">
                          <select
                            className="round user__form form-control form-load"
                            disabled={!profileEdit}
                            name="widgetEditValue"
                            value={widgetEditValue}
                            onChange={handleOptionSelect}
                          >
                            <option value={"true"}>True</option>
                            <option value={"false"}>False</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row m-b-1">
                      <div className="col-xs-6">
                        <label className="label-txt">Allow Bulk Loan</label>
                        <div className="form-group">
                          <select
                            className="round user__form form-control form-load"
                            disabled={!profileEdit}
                            name="bulkLoanApplicationValue"
                            value={bulkLoanApplicationValue}
                            onChange={handleOptionSelect}
                          >
                            <option value={0}></option>
                            <option value={1}>True</option>
                            <option value={2}>False</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <label className="label-txt">
                          Allow BVN Validation
                        </label>
                        <div className="form-group">
                          <select
                            className="round user__form form-control form-load"
                            disabled={!profileEdit}
                            name="allowBvnValidation"
                            value={allowBvnValidation}
                            onChange={handleOptionSelect}
                          >
                            <option value={"true"}>True</option>
                            <option value={"false"}>False</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row m-b-1">
                      <div className="col-xs-6">
                        <label className="label-txt">
                          Allow Account Number Validation
                        </label>
                        <div className="form-group">
                          <select
                            className="round user__form form-control form-load"
                            disabled={!profileEdit}
                            name="allowAccountNumberValidation"
                            value={allowAccountNumberValidation}
                            onChange={handleOptionSelect}
                          >
                            <option value={"true"}>True</option>
                            <option value={"false"}>False</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <label className="label-txt">Aggregator Type</label>
                        <div className="form-group">
                          <select
                            className="round user__form form-control form-load"
                            disabled={!profileEdit}
                            name="aggregatorType"
                            value={aggregatorType}
                            onChange={handleOptionSelect}
                          >
                            <option value={0}>No aggregator type</option>
                            {availableTypes}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row m-b-1">
                      <div className="col-xs-6">
                        <label className="label-txt">Aggregator Title</label>
                        <div className="form-group inputWithIcon">
                          <input
                            type="text"
                            className="user__form form-control"
                            onChange={inputChangeHandler}
                            placeholder="Aggregator Title"
                            data-form="profile"
                            readOnly={!profileEdit}
                            name="title"
                            value={title}
                          />
                          <i className="fas fa-user-circle" />
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <label className="label-txt">Max Running Loans</label>
                        <div className="form-group">
                          <input
                            type="number"
                            className="round user__form form-control form-load"
                            disabled={!profileEdit}
                            name="maximumRunningLoan"
                            value={maximumRunningLoan}
                            onChange={inputChangeHandler}
                          ></input>
                        </div>
                      </div>
                    </div>

                    <div className="grid__full">
                      {profileEdit ? (
                        <div className="Form__action-buttons">
                          <button
                            type="button"
                            className="btn zoom-element login-btn m-r-1"
                            data-form="profile"
                            onClick={cancelUpdateHandler}
                          >
                            <i className="fas fa-times-circle" />
                            &nbsp; Cancel
                          </button>
                          <button
                            type="button"
                            className="btn zoom-element login-btn m-l-1"
                            data-form="profile"
                            onClick={onProfileUpdate}
                            disabled={loading}
                          >
                            {isUpdating ? (
                              <i className="fas fa-spinner fa-spin" />
                            ) : (
                              <i className="fas fa-save" />
                            )}
                            &nbsp; Save
                          </button>
                        </div>
                      ) : (
                        <button
                          type="submit"
                          className="zoom-element btn login-btn"
                          data-form="profile"
                          onClick={editModeHandler}
                          disabled={loading || error}
                        >
                          {loading ? (
                            "Loading..."
                          ) : (
                            <Fragment>
                              <i className="fas fa-edit" />
                              &nbsp; Edit
                            </Fragment>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      {showToast && (
        <ToastSlim
          toastState={{ toastMessage, type: toastType, showToast }}
          onClearToast={() =>
            setState((prev) => {
              return { ...prev, showToast: false };
            })
          }
        />
      )}
    </div>
  );
};

export default AggregatorProfileSettings;
