import { ChangeEvent, useEffect, useState } from "react";

import { MDBTableHead, MDBTable, MDBTableBody } from "mdbreact";
import Paginator from "../../../../../../components/Pagination/Pagination.component";
import { Loader } from "../../../../../../UI/Loaders/Loaders";
import { formatMoney } from "../../../../../../helpers/formatter";
import { useCustomerStockDetails } from "../../../../../../custom-hooks/useCustomerStockDetails";
import { MixPanel } from "../../../../../../shared/MixPanel/MixPanel";

const StockData = ({ properties }: any) => {
  const [searchQuery, setSearchQuery] = useState({
    CustomerId: properties?.dataRecord?.borrower_details?.customer_id,
    Page: 1,
    PageSize: 20,
  });
  const [inputSearch, setInputSearch] = useState({
    Page: 1,
    PageSize: 20,
    DateFrom: "",
    DateTo: "",
  });

  useEffect(() => {
    // prettier-ignore
    MixPanel.track_pageview({ "page": "Stock Data" });
  }, []);

  const {
    data: stockdata,
    isLoading,
    error,
  } = useCustomerStockDetails(searchQuery);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    return setInputSearch((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSearch = () => {
    setSearchQuery((prev) => {
      return {
        ...prev,
        ...inputSearch,
      };
    });
  };

  let RenderedComponent = null;
  if (error) {
    RenderedComponent = (
      <div className="center-container">
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt">
              <i className="fas fa-bell" /> Unable to fetch Stock Information
            </h4>
          </div>
        </div>
      </div>
    );
  } else if (isLoading) {
    RenderedComponent = (
      <Loader text="Loading Stock Information ..." centered={true} />
    );
  } else if (stockdata) {
    if (stockdata?.data && stockdata?.data.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> No Stock Information available
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <>
          <MDBTable
            responsive
            minimal="true"
            striped
            bordered
            hover
            id="table-to-xls"
          >
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Product ID</th>
                <th>Product Category</th>
                <th>Customer Phone No</th>
                <th>Minimum Stock Quantity</th>
                <th>Total Quantity</th>
                <th>Available Quantity</th>
                <th>Reserved Quantity</th>
                <th>Unit Price</th>
                <th>Tax Amount</th>
                <th>Discount Amount</th>
                <th>Net Price</th>
                <th>Stock Level</th>
                <th>Date</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {stockdata &&
                stockdata?.data &&
                stockdata?.data.map((_data: any, _id: number) => {
                  const {
                    productId,
                    productCategory,
                    customerPhoneNo,
                    minimumStockQuantity,
                    totalQuantity,
                    availableQuantity,
                    reservedQuantity,
                    unitPrice,
                    taxAmount,
                    discountAmount,
                    netPrice,
                    stockLevel,
                    date,
                  } = _data;
                  return (
                    <tr key={_id}>
                      <td>{_id + 1}</td>
                      <td>{productId}</td>
                      <td>{productCategory}</td>
                      <td>{customerPhoneNo}</td>
                      <td>{minimumStockQuantity}</td>
                      <td>{totalQuantity}</td>
                      <td>{availableQuantity}</td>
                      <td>{reservedQuantity}</td>
                      <td>{formatMoney(unitPrice)}</td>
                      <td>{formatMoney(taxAmount)}</td>
                      <td>{formatMoney(discountAmount)}</td>
                      <td>{formatMoney(netPrice)}</td>
                      <td>{stockLevel}</td>
                      <td>{date?.substring(0, 10)}</td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>

          <Paginator
            size={searchQuery?.PageSize}
            page={searchQuery?.Page}
            count={stockdata?.totalCount}
            changeCurrentPage={(currentPage: number) =>
              setSearchQuery((prev) => ({ ...prev, Page: currentPage }))
            }
          />
        </>
      );
    }
  }

  return (
    <div className="dataTables_wrapper">
      <div
        id="activeEcosystem"
        className="Disbursement table-view animated speed-1x fadeInRight"
      >
        <div className="">
          <div className="flex-row m-b-2 m-t-1">
            <div className="d-flex">
              <div className="mr-4 d-flex">
                <span
                  style={{
                    display: "inline-block",
                    minWidth: "6rem",
                  }}
                >
                  Start Date:
                </span>
                <input
                  className="user__form form-control"
                  name="DateFrom"
                  type="date"
                  onChange={handleInputChange}
                  style={{
                    height: "2.55rem",
                    borderRadius: "5px",
                  }}
                />
              </div>
              <div className="mr-4 d-flex">
                <span
                  style={{
                    display: "inline-block",
                    minWidth: "6rem",
                  }}
                >
                  End Date:
                </span>
                <input
                  className="user__form form-control"
                  name="DateTo"
                  type="date"
                  onChange={handleInputChange}
                  style={{
                    height: "2.55rem",
                    borderRadius: "5px",
                  }}
                />
              </div>
              <div>
                <label htmlFor="table-fetch" className="form-group">
                  No. of results:{" "}
                  <select
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                    name="PageSize"
                    id="table-fetch"
                    className="table-fetch-select"
                    value={inputSearch?.PageSize}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </label>
              </div>
            </div>
            <div className="d-flex justify-content-center w-100">
              <button
                id="create"
                data-id="create-role"
                data-action="create"
                className="create btn advancly-btn"
                onClick={handleSearch}
              >
                <i className="fas fa-search m-r-1" />
                Search
              </button>
            </div>
          </div>
        </div>

        <div
          style={{
            height: "30rem",
            overflowY: "scroll",
          }}
        >
          {RenderedComponent}
        </div>
      </div>
    </div>
  );
};

export default StockData;
