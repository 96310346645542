import React, { useEffect } from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

// UI Components
import { Loader } from "../../../../UI/Loaders/Loaders";
import useAllAborrowersLoans from "../../../../custom-hooks/useAllABorrowersLoans";
import { currencyFormatter } from "../../../../helpers/stringControllers";
import { attachClassNameToTag } from "../../../../helpers/attachClassNameToTag";
import { accurateQuantifier } from "./../../../../helpers/accurateQuantifier";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";

const AllLoansTiedToABorrower = ({ dataRecord }) => {
  const { data, status, error } = useAllAborrowersLoans({
    borrower_id: dataRecord.borrower_id,
  });

  useEffect(() => {
    // prettier-ignore
    MixPanel.track_pageview({ "page": "All Loans" });
  }, []);

  //JSX Template
  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Borrower's Loans
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading all borrower's loans..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              borrower's loans, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (data && data.data && data.data.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no loans for
              this borrower
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <MDBTable responsive minimal="true" bordered hover>
          <MDBTableHead>
            <tr>
              <th>S/N</th>
              <th>Loan Ref</th>
              <th>Tenure</th>
              <th>Repayment Frequency</th>
              <th>Amount</th>
              <th>Amount to Repay</th>
              <th>Pub Date</th>
              <th>Effective Date</th>
              <th>Due Date</th>
              <th>Loan Status</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {data &&
              data.data &&
              data.data.map((loans, _id) => {
                const {
                  loan_amount,
                  loan_amount_repay,
                  loan_due_date,
                  status,
                  pub_date,
                  loan_effective_date,
                  loan_ref,
                  loan_tenure,
                  repayment_type,
                  currency,
                } = loans;
                return (
                  <tr key={_id}>
                    <td>{_id + 1}</td>
                    <td>{loan_ref}</td>
                    <td>{accurateQuantifier(loan_tenure, "day")}</td>
                    <td>{repayment_type}</td>
                    <td>{currencyFormatter(Number(loan_amount), currency)}</td>
                    <td>
                      {currencyFormatter(Number(loan_amount_repay), currency)}
                    </td>
                    <td>{pub_date.substring(0, 10)}</td>
                    <td>
                      {loan_effective_date
                        ? loan_effective_date.substring(0, 10)
                        : ""}
                    </td>
                    <td>{loan_due_date.substring(0, 10)}</td>
                    <td>
                      <span className={attachClassNameToTag(status)}>
                        {attachClassNameToTag(status)}
                      </span>
                    </td>
                  </tr>
                );
              })}
          </MDBTableBody>
        </MDBTable>
      );
    }
  }

  return (
    <div className="table-view speed-1x fadeInRight">{RenderedComponent}</div>
  );
};

export default AllLoansTiedToABorrower;
