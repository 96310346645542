import React from "react";
import { capitalize } from "../../helpers/stringControllers";

export const ProductCatergorySectorColumn = [
  { label: "Code", field: "productCategoryCode", sort: "asc", width: 50 },
  { label: "Sector", field: "sectorName", sort: "asc" },
  { label: "Product Category", field: "productCategoryName", sort: "asc" },
  { label: "MIn interest rate", field: "minimum_interest_rate", sort: "asc" },
  { label: "Max interest rate", field: "maximum_interest_rate", sort: "asc" },
  // { label: 'Active', field: 'is_active', sort: 'asc' },
  // { label: 'Date', field: 'modified_date', sort: 'asc' },
  // { label: 'Pub Date', field: 'pub_date', sort: 'asc' },
  { label: "Sector id", field: "sector_id", sort: "asc" },
  { label: "Min Tenure", field: "minimum_tenor", sort: "asc" },
  { label: "Max Tenure", field: "maximum_tenor", sort: "asc" },
  { label: "Min Amount", field: "minimum_amount", sort: "asc" },
  { label: "Max Amount", field: "maximum_amount", sort: "asc" },
  { label: "Product id", field: "product_category_id", sort: "asc" },
  { label: "Id", field: "id", sort: "asc" },
  { label: "Edit", field: "edit", sort: "asc" },
];

export const modifyProductTableRows = (
  rows: any,
  tableName: any,
  actions: any,
  handler: any
) => {
  let newTableRows = rows.map((row: any, i: number) => {
    // const { id, is_active } = row;
    const newRow = { ...row };

    const ManageButtons = (
      <div key={i} style={{ display: "flex" }}>
        {actions.map((action: any, j: number) => {
          return (
            <span
              key={j}
              id={j.toString()}
              data-payload={JSON.stringify(row)}
              data-table={tableName}
              data-action={action?.action}
              onClick={(e) => {
                handler({
                  modalIdentifier: action?.identifier,
                  dataRecord: row,
                  action: "",
                });
              }}
              className={`${tableName} ${action?.action} btn-manage zoom-element btn-slim`}
              style={{ backgroundColor: "#713fff" }}
            >
              {capitalize(action?.action)}
            </span>
          );
        })}
      </div>
    );

    newRow.edit = ManageButtons;

    return newRow;
  });

  return newTableRows;
};

//  Reorder an Array/responseDataRecords of records from the API
export const reOrderRows = (tableColumns: any, responseDataRecords: any) => {
  let newRows = [];

  if (responseDataRecords) {
    if (responseDataRecords.length > 0) {
      newRows = responseDataRecords.map((row: any) => {
        let newRow = {} as any;
        tableColumns.forEach((column: any) => {
          // if (column.field === "id") {
          //   newRow[column.field] = index + 1;
          //   return;
          // }
          if (column.field === "use_okra" || column.field === "use_paystack") {
            newRow[column.field] = row[column.field] ? "true" : "false";
          } else {
            newRow[column.field] = row[column.field] ? row[column.field] : null;
          }
        });
        return newRow;
      });
    } else {
      newRows = [];
    }
  }
  return {
    columns: tableColumns,
    rows: newRows,
  };
};

//  Reorder an Array/responseDataRecords of records from the API
export const reOrderAggregatorRows = (columns: any, records: any) => {
  let newRecords = [];

  if (records) {
    if (records.length > 0) {
      newRecords = records.map((record: any) => {
        let dis_channel_account = {} as any;
        let rep_channel_account = {} as any;
        let dis_channel_account_keys = [] as any;
        let rep_channel_account_keys = [] as any;

        if (record.disbursement_channel_account) {
          dis_channel_account = { ...record.disbursement_channel_account };
          dis_channel_account_keys = Object.keys(dis_channel_account);
        }
        if (record.repayment_channel_account) {
          rep_channel_account = { ...record.repayment_channel_account };
          rep_channel_account_keys = Object.keys(rep_channel_account);
        }

        let newRecord = {} as any;

        columns.forEach((column: any) => {
          if (record.hasOwnProperty(column.field)) {
            if (
              column.field === "use_okra" ||
              column.field === "use_paystack" ||
              column.field === "allow_batch_disbursement" ||
              column.field === "repay_principal_at_end_of_tenure" ||
              column.field === "require_aggregator_approval" ||
              column.field === "require_deal_slip" ||
              column.field === "require_bvn_verification"
            ) {
              newRecord[column.field] = record[column.field] ? "true" : "false";
            } else {
              newRecord[column.field] = record[column.field];
            }
          } else {
            if (
              record.disbursement_channel_account &&
              dis_channel_account_keys.includes(column.field)
            ) {
              newRecord[column.field] = dis_channel_account[column.field];
            } else if (
              record.repayment_channel_account &&
              rep_channel_account_keys.includes(column.field)
            ) {
              newRecord[column.field] = rep_channel_account[column.field];
            } else {
              newRecord[column.field] = null;
            }
          }
        });
        return newRecord;
      });
    } else {
      newRecords = [];
    }
  }

  return {
    columns,
    rows: newRecords,
  };
};

export const reOrderFunderProductRows = (
  tableColumns: any,
  responseDataRecords: any
) => {
  let newRows = [];

  if (responseDataRecords) {
    if (responseDataRecords.length > 0) {
      newRows = responseDataRecords.map((row: any) => {
        let newRow = {} as any;
        tableColumns.forEach((column: any) => {
          if (column.field === "mapping_id") {
            newRow[column.field] = row.id;
            return;
          }

          newRow[column.field] = row.product_details[column.field]
            ? row.product_details[column.field]
            : null;
          if (column.field === "funder_name") {
            newRow[column.field] = row[column.field];
          }
          if (column.field === "funder_id") {
            newRow[column.field] = row["xfunder_id"];
          }
        });
        return newRow;
      });
    } else {
      newRows = [];
    }
  }

  return {
    columns: tableColumns,
    rows: newRows,
  };
};

export const filterDataByColumns = (
  tableData: any,
  mode = "exclude",
  fields = [] as any
) => {
  const { columns, rows } = tableData;

  let newColumns = columns.filter((column: any) => {
    let newArrayOfColumns = [] as any;
    if (mode === "exclude") {
      newArrayOfColumns = fields.indexOf(column.field) === -1;
    } else if (mode === "include") {
      newArrayOfColumns = fields.indexOf(column.field) !== -1;
    }
    return newArrayOfColumns;
  });

  let newRows = rows.map((row: any) => {
    const filtered = Object.keys(row)
      .filter((key) =>
        mode === "exclude" ? !fields.includes(key) : fields.includes(key)
      )
      .reduce((obj: any, key) => {
        obj[key] = row[key];
        return obj;
      }, {});
    return { ...filtered };
  });

  return {
    columns: newColumns,
    rows: newRows,
  };
};

export const AggregatorsProduct = [
  { label: "Product Id", field: "id", sort: "asc" },
  { label: "Aggregator", field: "aggregator", sort: "asc" },
  { label: "Product Name", field: "product_name", sort: "asc" },
  { label: "Product Category", field: "productCategoryName", sort: "asc" },
  { label: "Max Tenure", field: "maximum_tenor", sort: "asc" },
  { label: "Interest Rate", field: "interest_rate", sort: "asc" },
  { label: "Currency", field: "currency", sort: "asc" },
  { label: "Max Amount", field: "maximum_amount", sort: "asc" },
  { label: "Repayment Frequency", field: "repayment_type", sort: "asc" },
  { label: "Repayer Type Id", field: "repayer_type_id", sort: "asc" },
  { label: "Repayer Type", field: "repayer_type_value", sort: "asc" },
  { label: "Disbursement Type Id", field: "disbursement_type_id", sort: "asc" },
  { label: "Disbursement Type", field: "disbursement_type_value", sort: "asc" },
  { label: "Aggregator id", field: "aggregator_id", sort: "asc" },
  { label: "Schedule Type Id", field: "loan_calculation_method", sort: "asc" },
  {
    label: "Schedule Type",
    field: "loan_calculation_method_value",
    sort: "asc",
  },
  { label: "Product category id", field: "product_category_id", sort: "asc" },
  { label: "Product code", field: "product_code", sort: "asc" },
  { label: "Repayment Channel", field: "repayment_channel", sort: "asc" },
  { label: "Repayment Channel Id", field: "repayment_channel_id", sort: "asc" },
  { label: "Disbursement Channel", field: "disbursement_channel", sort: "asc" },
  {
    label: "Disbursement Channel Id",
    field: "disbursement_channel_id",
    sort: "asc",
  },
  {
    label: "Disbursement Account Id",
    field: "dis_channel_acct_id",
    sort: "asc",
  },
  {
    label: "Disbursement Channel Account",
    field: "dis_channel_acct_number",
    sort: "asc",
  },
  { label: "Edit", field: "edit", sort: "asc" },
  {
    label: "Repayment Channel Account Id",
    field: "repayment_channel_account_id",
    sort: "asc",
  },
  {
    label: "Repayment Channel Account",
    field: "rep_channel_acct_number",
    sort: "asc",
  },
  {
    label: "Repayment Account number",
    field: "repayment_account_number",
    sort: "asc",
  },
  {
    label: "Repayment Account name",
    field: "repayment_account_name",
    sort: "asc",
  },
  {
    label: "Repayment channel account Id",
    field: "rep_channel_acct_id",
    sort: "asc",
  },
  { label: "Repayment Bank Id", field: "repayment_bank_id", sort: "asc" },
  { label: "Repayment bank name", field: "repayment_bank_name", sort: "asc" },
  { label: "Okra", field: "use_okra", sort: "asc" },
  { label: "Paystack", field: "use_paystack", sort: "asc" },
  { label: "Tenant", field: "core_banking_tenant", sort: "asc" },
  { label: "CB Product Id", field: "core_banking_product_id", sort: "asc" },
  {
    label: "CB Product Name",
    field: "core_banking_product_name",
    sort: "asc",
  },
  { label: "Moratorium", field: "moratorium_duration", sort: "asc" },
  { label: "Disbursement Bank Id", field: "bank_id", sort: "asc" },
  { label: "Disbursement Bank Name", field: "bank", sort: "asc" },
  {
    label: "Disbursement Account Number",
    field: "account_number",
    sort: "asc",
  },
  {
    label: "Disbursement Account Name",
    field: "bank_account_name",
    sort: "asc",
  },
  { label: "Loan Type Id", field: "loan_type_id", sort: "asc" },
  { label: "Loan Type", field: "loan_type", sort: "asc" },
  {
    label: "Batch Disbursement",
    field: "allow_batch_disbursement",
    sort: "asc",
  },
  {
    label: "Max Running Loans",
    field: "maximum_running_loan",
    sort: "asc",
  },
  {
    label: "End of Tenor Principal",
    field: "repay_principal_at_end_of_tenure",
    sort: "asc",
  },
  {
    label: "Moratorium Type",
    field: "moratorium_type",
    sort: "asc",
  },
  {
    label: "Requre Aggregator Approval",
    field: "require_aggregator_approval",
    sort: "asc",
  },
  {
    label: "Amortization Type",
    field: "amortization_type",
    sort: "asc",
  },
  {
    label: "Allow Sending of Deal Slip",
    field: "require_deal_slip",
    sort: "asc",
  },
  {
    label: "Require BVN Verification",
    field: "require_bvn_verification",
    sort: "asc",
  },
  {
    label: "Product Notification Types",
    field: "product_notification_type",
    sort: "asc",
  },
];

export const FundersProduct = [
  { label: "Id", field: "id", sort: "asc" },
  { label: "Funder", field: "funder_name", sort: "asc" },
  { label: "Funder Id", field: "funder_id", sort: "asc" },
  { label: "Mapping Id", field: "mapping_id", sort: "asc" },
  { label: "Aggregator", field: "aggregator_name", sort: "asc" },
  { label: "Aggregator Id", field: "aggregator_id", sort: "asc" },
  { label: "Product Name", field: "product_name", sort: "asc" },
  { label: "Product Category", field: "product_category_name", sort: "asc" },
  { label: "Amount", field: "amount", sort: "asc" },
  { label: "Product category id", field: "product_category_id", sort: "asc" },
  { label: "Product code", field: "product_code", sort: "asc" },
  { label: "Edit", field: "edit", sort: "asc" },
  // { label: 'Delete', field: 'delete', sort: 'asc' },
];
