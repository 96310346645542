import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { postDataWithDotNet } from "../../../../newApis/dotNetApiMethods";
import CustomSelectDropdown from "../../../../NewComponents/CustomHTMLElements/CustomSelectDropdown";
import ButtonLoader from "../../../../UI/Loaders/Loaders";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";

import { appInsights } from "../../../../config/appInsights";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";

export default function CreateBorrowerWallet({ properties }) {
  const [state, setState] = useState({
    aggregatorId: properties?.aggregator_Id || "",
    currency: "",
    loading: false,
    errorMessage: "",
    successMessage: "",
  });

  useEffect(() => {
    // prettier-ignore
    MixPanel.track_pageview({ "page": "Create Borrower Wallet" });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setState((prev) => ({ ...prev, loading: true }));
    let reqBody = {
      currency: state?.currency,
      targetId: properties?.dataRecord,
      consumerType: "Customer",
    };

    try {
      const response = await postDataWithDotNet(
        ajaxEndpoints.CREATE_WALLET,
        reqBody,
      );

      setState((prev) => ({ ...prev, successMessage: response?.message }));
    } catch (error) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "BorrowerWaller.jsx",
        },
      });
      setState((prev) => ({
        ...prev,
        errorMessage: error?.response?.data?.message,
      }));
    }

    setState((prev) => ({ ...prev, loading: false }));
  };
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const data = useSelector((state) => state?.aggregatorsReducer);

  const { partnerTableData } = data?.currentAggregator;

  return (
    <div className="Modal__dialog animated slideInDown" role="document">
      <div className="IssueModal Modal__content" style={{ overflow: "hidden" }}>
        {state?.errorMessage && (
          <p className="alert alert-danger mb-4 small">{state?.errorMessage}</p>
        )}
        {state?.successMessage && (
          <p className="alert alert-success mb-4 small">
            {state?.successMessage}
          </p>
        )}
        <div className="Modal__body">
          <h3
            className="Modal__title m-b-2 p-x-2 p-y-1 "
            style={{ backgroundColor: "#713fff", color: "#fff" }}
          >
            Create wallet
          </h3>

          <form>
            <div className="row">
              <div className="col-xs-12 mb-4">
                <CustomSelectDropdown
                  label="Customer"
                  name="customer_id"
                  value={properties?.dataRecord}
                  onChange={(e) => handleChange(e)}
                  className="round user__form form-control"
                  disabled={properties?.dataRecord}
                >
                  <option value="">Customer</option>
                  {partnerTableData.length > 0 &&
                    partnerTableData.map((aggregator) => {
                      const { first_name, last_name, customer_id } = aggregator;
                      return (
                        <option value={Number(customer_id)} key={customer_id}>
                          {first_name} {last_name}
                        </option>
                      );
                    })}
                </CustomSelectDropdown>
              </div>
              <div className="col-xs-12 mb-4">
                <CustomSelectDropdown
                  label="Currency"
                  name="currency"
                  value={state?.currency}
                  onChange={(e) => handleChange(e)}
                  className="round user__form form-control"
                >
                  <option value=""></option>

                  <option value="NGN">Naira</option>

                  <option value="USD">Dollar</option>
                </CustomSelectDropdown>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-4 mb-4"></div>
              <div className="col-xs-4 mb-4 ">
                <div className="grid__full m-t-2">
                  <button
                    type="submit"
                    className="zoom-element btn login-btn"
                    onClick={(e) => handleSubmit(e)}
                  >
                    &nbsp; Proceed {state.loading && <ButtonLoader />}
                  </button>
                </div>
              </div>

              <div className="col-xs-4 mb-4"></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
