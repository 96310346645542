import React, { useEffect, useState } from "react";
import { IcreditBureauProps } from "./CreditBureauCRC";
import useCreditBureauCheckHistory from "../../../../custom-hooks/useCreditBureauCheckHistory";
import useCreditBureauAllChecks from "../../../../custom-hooks/useCreditBureauAllChecks";
import CreditBureauCheckTableData from "./CreditBureauCheckTableData";
import Loader from "../../../../NewComponents/Loader/Loader.component";
import moment from "moment";
import Paginator from "../../../../components/Pagination/Pagination.component";
import NoTableDataAvailable from "../../../../NewComponents/NoTableDataAvailable/NoTableDataAvailable.component";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";

const CreditBureauCheckHistory = ({
  first_name,
  last_name,
  bvn,
  borrower_type_value,
  rc_number,
  customer_id,
}: IcreditBureauProps) => {
  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 10,
  });
  const [count, setCount] = useState(null);

  const [showCreditBureauChecks, setShowCreditBureauChecks] = useState(false);
  const [currentDateGenerated, setCurrentDateGenerated] = useState<string>("");

  useEffect(() => {
    // prettier-ignore
    MixPanel.track_pageview({ "page": "Credit Bureau Check History" });
  }, []);

  const {
    data: creditBureauHistoryData,
    isLoading: creditBureauLoading,
    error: creditBureauError,
  } = useCreditBureauCheckHistory({
    customerId: Number(customer_id),
    page: preState.pageNumber,
    pageSize: preState.size,
  });
  const {
    data: creditBureauAllChecksData,
    isLoading: creditBureauAllChecksLoading,
    error: creditBureauAllChecksError,
    refetch: refetchCreditBureauAllChecksData,
  } = useCreditBureauAllChecks({
    customerId: Number(customer_id),
    currentDateGenerated: currentDateGenerated,
  });
  const handleCreditBureauHistory = (dateGenerated: string) => {
    if (dateGenerated) {
      setShowCreditBureauChecks((prevState) => !prevState);

      setCurrentDateGenerated(dateGenerated);
    }
  };
  if (creditBureauHistoryData) {
    if (count === null) {
      console.log(creditBureauHistoryData?.totalCount);
      setCount(creditBureauHistoryData?.totalCount);
    }
  }
  useEffect(() => {
    if (currentDateGenerated !== "") {
      refetchCreditBureauAllChecksData();
    }
    // eslint-disable-next-line
  }, [currentDateGenerated]);
  return (
    <div>
      {creditBureauError && (
        <p className="alert alert-dange mt-4 small ">
          {creditBureauError as string}
        </p>
      )}
      {creditBureauAllChecksError && (
        <p className="alert alert-dange mt-4 small ">
          {creditBureauAllChecksError as string}
        </p>
      )}
      <h2 className="text-bold py-3">Credit Bureau Check History</h2>
      <div>
        <p>
          Borrower Name:
          <span className="text-weight-bold">
            {" "}
            {` ${first_name} ${last_name}`}
          </span>
        </p>
        <p>
          Customer Type:{" "}
          <span className="text-weight-bold">{borrower_type_value}</span>{" "}
        </p>
        <p>
          {" "}
          <span className="text-weight-bold">
            {" "}
            {borrower_type_value.toLowerCase() === "individual"
              ? `BVN: ${bvn}`
              : `RC Number: ${rc_number}`}
          </span>
        </p>
      </div>
      <div>
        {creditBureauLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <p>Loading......</p>
          </div>
        ) : (
          <>
            <table className="table-style">
              <thead>
                <tr>
                  <th className="th-td-style th-style " scope="col">
                    Date Generated
                  </th>
                  <th className="th-td-style th-style " scope="col">
                    Credit Score
                  </th>
                  <th className="th-td-style th-style" scope="col">
                    Provider
                  </th>
                  <th className="th-td-style th-style" scope="col">
                    Report
                  </th>
                </tr>
              </thead>
              <tbody>
                {creditBureauHistoryData &&
                creditBureauHistoryData?.data?.length > 0 ? (
                  creditBureauHistoryData?.data?.map(
                    (bureauhistory: any, index: number) => {
                      const { creditScore, provider, dateGenerated } =
                        bureauhistory;
                      return (
                        <tr key={index}>
                          <td className="th-td-style ">
                            {moment(dateGenerated).format("DD/MM/YYYY")}
                          </td>

                          <td className="th-td-style ">{creditScore}</td>
                          <td className="th-td-style ">{provider}</td>
                          <td className="th-td-style ">
                            <button
                              className="btn advancly-btn cursor-pointer"
                              onClick={() => {
                                handleCreditBureauHistory(dateGenerated);
                              }}
                            >
                              {creditBureauAllChecksLoading &&
                              currentDateGenerated === dateGenerated ? (
                                <Loader />
                              ) : (
                                `${
                                  showCreditBureauChecks &&
                                  currentDateGenerated === dateGenerated
                                    ? "Hide Report"
                                    : " View Report"
                                }`
                              )}
                            </button>
                          </td>
                        </tr>
                      );
                    },
                  )
                ) : (
                  <NoTableDataAvailable text={"No Records Available"} />
                )}
              </tbody>
            </table>
            <Paginator
              size={preState.size}
              page={preState.pageNumber}
              count={count || 0}
              changeCurrentPage={(pageNumber: number) =>
                setPreState({ ...preState, pageNumber })
              }
            />
          </>
        )}
        {creditBureauAllChecksLoading ? (
          <div className="d-flex justify-content-center align-items-center py-2">
            <p> Loading Data.....</p>
          </div>
        ) : (
          <div>
            {showCreditBureauChecks && (
              <CreditBureauCheckTableData
                creditBureauCheckData={
                  creditBureauAllChecksData?.consumerHitResponse?.body
                }
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CreditBureauCheckHistory;
