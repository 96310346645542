import {useQuery} from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import {getData} from "../newApis/apiMethods";


async function getLoanOffer(loan_ref: string) {
    const response = await getData(ajaxEndpoints.GET_LOAN_OFFERS + "?loan_ref=" + loan_ref);

    return response.data;
}

export function useFetchLoanOffers(loan_ref: string) {
    return useQuery([loan_ref, "getLoanOffer",], getLoanOffer,  {
        enabled: loan_ref ? true : false,
    })
}