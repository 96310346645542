// Aggregator Details
import {
  mifosRepaymentColumn,
  reOrderTableData,
} from "../../../../../redux/actions/loans";
import { MDBDataTable } from "mdbreact";
import React, { useEffect } from "react";
import { MixPanel } from "../../../../../shared/MixPanel/MixPanel";

export const LoanSchedule = (props: any) => {
  const { mifosRepayment, error, loader } = props;

  useEffect(() => {
    // prettier-ignore
    MixPanel.track_pageview({ "page": "Core Banking - Loan Schedule" });
  }, []);

  let RenderedComponent = null;
  if (error || mifosRepayment === undefined) {
    RenderedComponent = (
      <div className="animated fadeInRight">
        <h4 className="p-y-5 text-center">
          <i className="fas fa-bell" /> Unable to Fetch mifos details
        </h4>
      </div>
    );
  } else if (mifosRepayment === null || loader) {
    RenderedComponent = (
      <div
        id="adv-login-loader"
        className="loading-overlay speed-1x fadeInRight"
      >
        <div className="form-loader form-loader-login verify">
          <div className="centerLoader">
            <div className="loading" id="adv-loader-alt" />
            <div className="center-txt webapp-ripple-text">
              <i className="notifier fas fa-user-circle" />
              &nbsp; Loading mifos Details
            </div>
          </div>
        </div>
      </div>
    );
  } else if (mifosRepayment.length) {
    // Verify that a loans object exists
    if (mifosRepayment.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
            <h4 className="p-y-5 text-center">
              <i className="fas fa-bell" /> There are currently no mifos
              repayment details
            </h4>
          </div>
        </div>
      );
    } else {
      const mifosAmountDetails = reOrderTableData(
        mifosRepaymentColumn,
        mifosRepayment,
      );

      RenderedComponent = (
        <div>
          <MDBDataTable
            responsive
            minimal="true"
            striped
            bordered
            hover
            data={mifosAmountDetails}
          />
        </div>
      );
    }
  }
  return (
    <React.Fragment>
      <div>{RenderedComponent}</div>
    </React.Fragment>
  );
};
