import { useEffect } from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

// Router
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { showModal } from "./../../../redux/actions/modal";

// UI Components
import { Loader } from "../../../UI/Loaders/Loaders";
import Paginator from "../../Pagination/Pagination.component";
import {
  getAllLoans,
  setLoanPageCount,
  setLoanPageSize,
  setLoanPageNumber,
  setLoanFilterStartDate,
  setLoanFilterEndDate,
  setLoanStatusValue,
  setLoanStartDateValue,
  setLoanEndDateValue,
  setLoanDisbursedValue,
  setLoanApprovedValue,
  setLoanOnMifos,
  setBorrowerName,
  setLoanId,
  setBvn,
  setRcNumber,
  setAggregatorId,
  setLoanRef,
} from "../../../redux/actions/loans";

import { attachClassNameToTag } from "../../../helpers/attachClassNameToTag";
import { formatMoney } from "../../../helpers/formatter";
import { MixPanel } from "../../../shared/MixPanel/MixPanel";

const Loan = (props) => {
  const {
    showModal,
    getAllLoans,
    setLoanPageCount,
    setLoanPageSize,
    setLoanPageNumber,
    loans: data,
    setLoanApprovedValue,
    setLoanEndDateValue,
    setLoanStartDateValue,
    setLoanDisbursedValue,
    setLoanStatusValue,
    setLoanFilterEndDate,
    setLoanFilterStartDate,
    setLoanOnMifos,
    setBorrowerName,
    setLoanId,
    setBvn,
    setRcNumber,
    setAggregatorId,
    setLoanRef,
  } = props;
  let {
    records,
    loading,
    error,
    startDate,
    endDate,
    size,
    pageNumber,
    count,
    is_approved,
    is_disbursed,
    loan_status,
    loanStartDate,
    loanEndDate,
    IsMigratedtoCBA,
    borrowerName,
    loanId,
    bvn,
    rcNumber,
    aggregatorId,
    loanRef,
  } = data;
  //  Methods
  const modalHandler = (e, tableName, modalRow, action, type) => {
    if (type) {
      showModal({
        modalIdentifier: "audittrail",
        dataRecord: modalRow,
        eventTarget: e.target,
        action: "customrange",
        type,
      });
    } else {
      showModal({
        modalIdentifier: tableName,
        dataRecord: modalRow,
        eventTarget: e.target,
        action: action,
        type,
      });
    }
  };
  const getLoanRecordsModal = () => {
    showModal({
      modalIdentifier: "loan",
      dataRecord: [],
      action: "getloanrecords",
    });
  };

  const resetTable = () => {
    setLoanPageCount(null);
    setLoanPageNumber(1);
  };
  const resetSearch = (e) => {
    e.preventDefault();
    setLoanPageCount(null);
    setLoanPageNumber(1);
    setLoanPageSize(20);
    setLoanFilterStartDate("");
    setLoanFilterEndDate("");
    setLoanStatusValue("");
    setLoanStartDateValue("");
    setLoanEndDateValue("");
    setLoanDisbursedValue("");
    setLoanApprovedValue("");
    setLoanOnMifos("");
    setBorrowerName("");
    setLoanId(null);
    setBvn("");
    setRcNumber("");
    setAggregatorId(null);
    setLoanRef("");
    getAllLoans({
      page: 1,
      size: 20,
    });
  };

  // const filterData = (e) => {
  //   e.preventDefault();
  //   const diffTime = new Date(endDate) - new Date(startDate);
  //   if (diffTime < 0 || !startDate || !endDate) {
  //     modalHandler(e, "", "", "customrange", "alert");
  //     return;
  //   }
  //   setLoanDisbursedValue("");
  //   setLoanApprovedValue("");
  //   setLoanStatusValue("");
  //   setLoanStartDateValue("");
  //   setLoanEndDateValue("");
  //   setLoanOnMifos("");
  //   resetTable();
  //   getAllLoans({
  //     start_date: startDate,
  //     end_date: endDate,
  //     page: pageNumber,
  //     size,
  //   });
  // };
  const handlePaginationChange = (pageNumber) => {
    setLoanPageNumber(pageNumber);
    getAllLoans({
      start_date: startDate,
      end_date: endDate,
      page: pageNumber,
      size,
      disbursed: is_disbursed,
      approved: is_approved,
      status: loan_status,
      disbursement_from: loanStartDate,
      disbursement_to: loanEndDate,
      IsMigratedtoCBA,
      borrowerName,
      loanId,
      bvn,
      rcNumber,
      aggregatorId,
      loanRef,
    });
  };
  //  Life Cycle Hooks
  useEffect(() => {
    // prettier-ignore
    MixPanel.track_pageview({ "page": "Loans" });
  }, []);

  useEffect(() => {
    //  If user is not authenticates, redirect to login
    if (!props.isAuthenticated) {
      props.history.push("/login");
    }
    getAllLoans({
      page: pageNumber,
      size,
    });
    // eslint-disable-next-line
  }, []);

  //JSX Template
  let RenderedComponent = null;

  if (!records && !loading) {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Audit trails
          </h4>
        </div>
      </div>
    );
  } else if (loading) {
    RenderedComponent = <Loader centered={true} text="Loading all loans..." />;
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              loans, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data.records && data.records.length === 0) ||
      (data && !data.records)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no logged
              activities.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <MDBTable responsive minimal="true" bordered hover>
            <MDBTableHead>
              <tr>
                <th>ID</th>
                <th>MANAGE</th>
                <th>ECOSYSTEM</th>
                <th>AGGREGATOR NAME</th>
                <th>FIRST NAME</th>
                <th>LAST NAME</th>
                <th>BORROWER TYPE</th>
                <th>LOAN STATUS</th>
                <th>LOANS AMOUNT</th>
                <th>REQUESTED AMOUNT</th>
                <th>LOAN INTEREST</th>
                <th>LOAN REF</th>
                <th>TRUST SCORE</th>
                <th>CONSOLIDATED SCORE DECISION</th>
                <th>SUPERFLUID SCORE</th>
                <th>SUPERFLUID STATUS</th>
                <th>CREATED DATE</th>
                <th>ROLL OVER STATUS</th>
                <th>ROLL OVER REF</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {data &&
                data.records
                  // .slice((pageNumber - 1) * size, size * (pageNumber - 1) + size)
                  .map((loan, _id) => {
                    const {
                      loan_id,
                      borrower_details,
                      aggregator_details,
                      status,
                      loan_amount,
                      loan_interest,
                      loan_ref,
                      borrower_trust_score,
                      pub_date,
                      disbursement_decision,
                      sf_score,
                      sf_status,
                      loan_amount_requested,
                      rollover_loan_reference,
                      is_rollover,
                    } = loan;
                    const currency = aggregator_details?.currency;
                    return (
                      <tr key={loan_id}>
                        <td>{loan_id}</td>
                        <td>
                          <div class="dropdown">
                            <button
                              className="btn zoom-element btn-slim dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            ></button>
                            <div
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <span
                                key="0"
                                id="0"
                                data-id={loan_id}
                                data-table={"Loan"}
                                data-action="View"
                                className="Loan cursor-pointer dropdown-item"
                                onClick={(e) => {
                                  modalHandler(e, "Loan", loan, "View");
                                }}
                              >
                                DETAILS
                              </span>

                              <span
                                key={"1"}
                                id="1"
                                data-id={loan_id}
                                data-table={"Loan"}
                                data-action="Mifos"
                                className="Loan cursor-pointer dropdown-item"
                                onClick={(e) => {
                                  modalHandler(e, "Loan", loan, "Mifos");
                                }}
                              >
                                CORE BANKING
                              </span>

                              <span
                                key="2"
                                id="2"
                                data-id={loan_id}
                                data-table={"Loan"}
                                data-action="Repay"
                                className="Loan cursor-pointer dropdown-item"
                                onClick={(e) => {
                                  modalHandler(e, "Loan", loan, "Repay");
                                }}
                              >
                                REPAY
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>{aggregator_details.biz_category}</td>
                        <td>{aggregator_details.biz_name}</td>
                        <td>{borrower_details.first_name}</td>
                        <td>{borrower_details.last_name}</td>
                        <td>{borrower_details.borrower_type_value}</td>
                        <td>
                          <span className={attachClassNameToTag(status)}>
                            {attachClassNameToTag(status)}
                          </span>
                        </td>
                        <td>{formatMoney(Number(loan_amount), currency)}</td>
                        <td>
                          {formatMoney(Number(loan_amount_requested), currency)}
                        </td>
                        <td>{formatMoney(Number(loan_interest), currency)}</td>
                        <td>{loan_ref}</td>
                        <td>{borrower_trust_score}</td>
                        <td>{disbursement_decision}</td>
                        <td>{sf_score}</td>
                        <td>{sf_status}</td>
                        <td>{pub_date.substring(0, 10)}</td>
                        <td>{is_rollover ? "True" : "False"}</td>
                        <td>{rollover_loan_reference}</td>
                      </tr>
                    );
                  })}
            </MDBTableBody>
          </MDBTable>
        </div>
      );
    }
  }

  return (
    <div className="dataTables_wrapper">
      <div className="table-view speed-1x fadeInRight">
        {/*Loans Header*/}
        <div className="block-header">
          <div className="d-flex flex-row flex-wrap justify-content-between align-items-baseline">
            <div className="dashboard-title">
              <h3 className="m-l-1"> All Loans</h3>
            </div>
            <div
              className="m-l-1 m-b-1"
              style={{
                display: "flex",
                justifyContent: "center",
                height: "30px",
              }}
            >
              {/* <TableFetchSelect
                style={{ marginRight: "20px" }}
                label="Showing results for: "
                id="table-fetch"
                className="table-fetch-select"
                onChange={(e) =>
                  onTableFetchSelectChange(
                    e,
                    showModal,
                    getAllLoans,
                    "getAllLoans",
                    `search_by=`
                  )
                }
                value={searchBy}
                options={[...tableFetchSelectOptionsAlt]}
              /> */}

              <button
                id="create"
                data-id="create-role"
                data-action="create"
                className="m-r-2 create btn advancly-btn"
                onClick={getLoanRecordsModal}
              >
                <i className="fas fa-download" /> &nbsp; Download Loans
              </button>
              <button
                id="create"
                data-id="create-role"
                data-action="create"
                className="m-r-1 create btn advancly-btn"
                onClick={(e) => {
                  modalHandler(e, "loan", "", "advance search");
                }}
              >
                <i className="fas fa-search" />
                &nbsp; Advance Search
              </button>
              <button
                id="create"
                data-id="create-role"
                data-action="create"
                className="m-r-1 btn advancly-white-btn"
                onClick={(e) => {
                  resetSearch(e);
                }}
              >
                <i className="fas fa-refresh" />
              </button>
            </div>
          </div>
          <div className="d-flex flex-row flex-wrap justify-content-between">
            <div className="d-flex flex-row flex-wrap justify-content-start m-l-1 m-t-1">
              <label htmlFor="table-fetch" className="form-group">
                Number of results:{" "}
                <select
                  onChange={(e) => {
                    resetTable();
                    setLoanPageSize(e.target.value);
                  }}
                  id="table-fetch"
                  className="table-fetch-select m-l-1"
                  value={size}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </label>
            </div>
          </div>
        </div>

        {/*Datatable || Loader || Error Message*/}
        {RenderedComponent}

        <Paginator
          size={size}
          page={pageNumber}
          count={count}
          changeCurrentPage={(pageNumber) => handlePaginationChange(pageNumber)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    authReducer: { isAuthenticated, loggedInUser },
    loansReducer: { loans },
  } = state;
  return {
    isAuthenticated,
    loggedInUser,
    loans,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    showModal,
    getAllLoans,
    setLoanPageCount,
    setLoanPageSize,
    setLoanPageNumber,
    setLoanApprovedValue,
    setLoanEndDateValue,
    setLoanStartDateValue,
    setLoanDisbursedValue,
    setLoanStatusValue,
    setLoanFilterEndDate,
    setLoanFilterStartDate,
    setLoanOnMifos,
    setBorrowerName,
    setLoanId,
    setBvn,
    setRcNumber,
    setAggregatorId,
    setLoanRef,
  })(Loan),
);
