import React, { Component, Fragment } from "react";

// Redux
import { connect } from "react-redux";
import { showModal } from "../../../redux/actions/modal";

// import Dropzone from 'react-dropzone';
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

// UI Components
import { Loader } from "../../../UI/Loaders/Loaders";
import Paginator from "./../../Pagination/Pagination.component";
import { formatMoney } from "../../../helpers/formatter";
import { MixPanel } from "../../../shared/MixPanel/MixPanel";

class FailedRepayment extends Component {
  state = {
    Loading: false,
    error: "",
    size: 10,
    pageNumber: 1,
  };

  componentDidMount() {
    // prettier-ignore
    MixPanel.track_pageview({ "page": "Failed Repayment" });
  }

  dialogHandler = ({ action, data }) => {
    this.props.showModal({
      action,
      dataRecord: data,
      modalIdentifier: "FailedRepayment",
    });
  };
  resetTable = () => {
    this.setState({
      pageNumber: 1,
    });
  };

  //JSX Template
  render() {
    const { Loading, failedRepaymentData, error } = this.props;
    const { size, pageNumber } = this.state;
    let RenderedComponent = null;

    // //  If loans object is equal to null e.g. Initial component render before API fetch
    if (error) {
      RenderedComponent = (
        <div className="center-container">
          <div className="centerLoader">
            <div className="grid__padding animated fadeInRight">
              <h4 className="center-txt">
                <i className="fas fa-bell" /> Unable to Fetch Loans
              </h4>
            </div>
          </div>
        </div>
      );
    } else if (Loading) {
      RenderedComponent = (
        <Loader text="Loading Failed Repayments ..." centered={true} />
      );
    } else if (failedRepaymentData?.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are Currently No Failed
              Repayments
            </h4>
          </div>
        </div>
      );
    } else if (failedRepaymentData?.length > 0) {
      RenderedComponent = (
        <Fragment>
          <MDBTable responsive minimal="true" bordered striped hover>
            <MDBTableHead>
              <tr>
                <th>ID</th>
                <th>Aggregator Name</th>
                <th>Borrower Name</th>
                <th>Repayment Amount</th>
                <th>Loan Ref</th>
                <th>Manage</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {failedRepaymentData &&
                failedRepaymentData
                  .slice(
                    (pageNumber - 1) * size,
                    size * (pageNumber - 1) + size,
                  )
                  .map((data, _id) => {
                    const {
                      currency,
                      repayment_id,
                      borrower_full_name,
                      aggregator_name,
                      repayment_amount,
                      loan_ref,
                    } = data;
                    return (
                      <tr key={_id}>
                        <td>{repayment_id}</td>
                        <td>{aggregator_name}</td>
                        <td>{borrower_full_name}</td>
                        <td>
                          {formatMoney(Number(repayment_amount), currency)}
                        </td>
                        <td>{loan_ref}</td>
                        <td className="manage-options">
                          <span
                            className="details btn-manage zoom-element btn-slim"
                            onClick={() => {
                              this.dialogHandler({
                                action: "details",
                                data,
                              });
                            }}
                          >
                            Details
                          </span>
                          <span
                            className="retry btn-manage zoom-element btn-slim"
                            onClick={() =>
                              this.dialogHandler({
                                action: "retry",
                                data,
                              })
                            }
                          >
                            Retry
                          </span>
                        </td>
                      </tr>
                    );
                  })}
            </MDBTableBody>
          </MDBTable>
          <Paginator
            size={size}
            page={pageNumber}
            count={failedRepaymentData && failedRepaymentData.length}
            changeCurrentPage={(pageNumber) => this.setState({ pageNumber })}
          />
        </Fragment>
      );
    }
    return (
      <div className="dataTables_wrapper">
        <div className="m-l-1 m-t-2 m-b-1">
          <label htmlFor="table-fetch" className="form-group">
            Number of results:{" "}
            <select
              onChange={(e) => {
                this.resetTable();
                this.setState({ size: e.target.value });
              }}
              id="table-fetch"
              className="table-fetch-select"
              value={size}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </label>
        </div>
        <div
          id="activeEcosystem"
          className="Disbursement table-view animated speed-1x fadeInRight"
        >
          <div className="m-l-1 m-r-1">{RenderedComponent}</div>
        </div>
      </div>
    );
  }
}

export default connect(null, { showModal })(FailedRepayment);
