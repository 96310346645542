import React, { useEffect } from "react";
import ModalDetailsRow from "../../../components/ModalDetailsRow/ModalDetailsRow.component";
import { MixPanel } from "../../../shared/MixPanel/MixPanel";

const RoleCreationModal = ({
  properties,
  properties: { dataRecord },
  onCloseModal,
}) => {
  const { code, name } = dataRecord;

  useEffect(() => {
    // prettier-ignore
    MixPanel.track_pageview({ "page": "Role Creation" });
  }, []);

  return (
    <div className="Modal__dialog animated slideInDown" role="document">
      <div
        className="AuditTrailModal Modal__content"
        style={{ overflow: "hidden" }}
      >
        <div className="Modal__body text-center">
          <h3 className="Modal__title p-b-2 text-center">Role Information</h3>
          <form>
            <ModalDetailsRow
              label="Role Name"
              value={name}
              icon="fas fa-user-circle"
            />
            <ModalDetailsRow
              label="Role Code"
              value={code}
              icon="fas fa-user-circle"
            />
          </form>
          <button
            type="button"
            className="btn zoom-element btn login-btn m-t-1"
            onClick={onCloseModal}
            data-dismiss="modal"
          >
            <i className="fas fa-times-circle"></i>
            &nbsp; Go Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default RoleCreationModal;
