import { useEffect, useState } from "react";

import Tabs from "../../../../components/Tabs/Tabs";

import { DownloadUploadedDocument } from "../../../../NewComponents/UploadedDocumentCards/UploadedDocumentCard.component";
import useDocumentViewer from "../../../../NewComponents/CustomViewDocumentHandler/CustomViewDocumentHandler";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";

const ViewLoanRepaymentDocumentsModal = ({
  dataRecord: documentUrl,
  onCloseModal,
}: any) => {
  const [scene, setScene] = useState("view_documents");

  const { handleViewDocument } = useDocumentViewer();

  useEffect(() => {
    // prettier-ignore
    MixPanel.track_pageview({ "page": "Loan Repayment Documents" });
  }, []);

  const [tabs] = useState([
    {
      name: "Documents",
      id: "view_documents",
      icon: "far fa-file",
      identifier: "repaymentInfoTab",
    },
  ]);

  const toggleTabContent = (content: string) => {
    return setScene(content);
  };

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div className="LoanModal Modal__content">
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          <h3 className="Modal__title p-b-1">
            <div className="row m-b-1">
              <Tabs onContentToggle={toggleTabContent} tabs={tabs} />
            </div>
          </h3>

          {(() => {
            switch (scene) {
              case "view_documents":
                return (
                  <div>
                    {!documentUrl ? (
                      <span>No Repayment Document Available</span>
                    ) : (
                      <DownloadUploadedDocument
                        name="Repayment Document"
                        pathname={documentUrl}
                        handleView={() =>
                          handleViewDocument(false, documentUrl)
                        }
                      />
                    )}
                  </div>
                );

              default:
                return null;
            }
          })()}

          <div className="Form__action-buttons m-t-2">
            <button
              type="button"
              className="btn zoom-element btn login-btn"
              onClick={onCloseModal}
              data-dismiss="modal"
            >
              <i className="fas fa-times-circle"></i>
              &nbsp; Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewLoanRepaymentDocumentsModal;
