import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";

import ajaxMethods from "../../../../api/ajax-methods";
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";
import { appInsights } from "../../../../config/appInsights";
import ToggleTab from "../../../../NewComponents/ToggleTab/ToggleTab.component";
import DisbursementSummary from "./CreditAnalysis/DisbursementSummary/DisbursementSummary";
import { IFunderOptions, ISingleLoanDisbursement, IState } from "./interface";
import CreditScore from "./CreditAnalysis/CreditScore/CreditScore";
import UploadBankStatement from "./CreditAnalysis/UploadBankStatement/UploadBankStatement";
import AccountStatistics from "./CreditAnalysis/AccountStatistics/AccountStatistics";
import BankStatement from "./CreditAnalysis/BankStatement/BankeStatement";
import LoanHistory from "./CreditAnalysis/LoanHistory/LoanHistory";
import StockData from "./CreditAnalysis/StockData/StockData";
import Orders from "./CreditAnalysis/Orders/Orders";
import LoanSchedule from "./CreditAnalysis/LoanSchedule/loanSchedule";
import { dataProps, statusProps } from "./CreditAnalysis/LoanSchedule/type";
import Alert from "../../../../NewComponents/TypedComponents/Alert/Alert.component";
import OrderItems from "./CreditAnalysis/Orders/OrderItems";
import DirectDebit from "./CreditAnalysis/DirectDebit/DirectDebit";
import { useDispatch } from "react-redux";
import { refetchPendingDisbursements } from "../../../../redux/actions/loans";
import CreditBureauCRC from "../CreditBureauCRC/CreditBureauCRC";
import CreditBureauCheckHistory from "../CreditBureauCRC/CreditBureauCheckHistory";
import Card from "./CreditAnalysis/Card/Card";
import {
  handleSwalErrorAlert,
  handleSwalSuccessAlert,
} from "../../../../helpers";
import BankStatementAnalysis from "./CreditAnalysis/BankStatementAnalysis/BankStatementAnalysis";
import { useFetchLoanOffers } from "../../../../custom-hooks/useFetchLoanOffer";
import { useQueryCache } from "react-query";

const SingleLoanDisbursement = ({
  first_name,
  last_name,
  loan_amount,
  loan_id,
  onCloseModal,
  properties,
  funderOptions,
  loan_ref,
  borrower_id,
  bvn,
  borrower_type_value,
  rc_number,
  customer_id,
}: ISingleLoanDisbursement) => {
  const [activeTab, setActiveTab] = useState("disbursement-summary");
  const [isTabClickabale, setIsTabClickable] = useState(false);
  const [loanInfoFromSchedule, setLoanInfoFromSchedule] = useState({});
  const [loanScheduleResponse, setLoanScheduleResponse] = useState<dataProps>();
  const [saveLoanDetailsResponse, setsaveLoanDetailsResponse] =
    useState<statusProps>();
  const [state, setState] = useState<IState>({
    comment: properties?.dataRecord?.disbursement_tag,
    error: false,
    selectedFunder: "",
    selectedDate: properties.dataRecord?.loan_effective_date?.substring(0, 10),
    amountApproved: loan_amount,
    approvedTenor: properties?.dataRecord?.loan_tenure,
    firstRepaymentDate: properties?.dataRecord?.first_repayment_date?.substring(
      0,
      10,
    ),
  });
  const [triggerNewLoanOffer, setTriggerNewLoanOffer] = useState(false);
  const [orderItemlist, setOrderItemList] = useState([]);
  const [switchState, setSwitchState] = useState<boolean>(false);
  const [loanOfferState, setloanOfferState] = useState({
    isLoading: false,
    error: "",
    success: false,
  });
  const queryCache = useQueryCache();

  const dispatch = useDispatch();

  const { data: loanOffer, isLoading: isLoadingLoanOffers } =
    useFetchLoanOffers(loan_ref);

  const renderContent = () => {
    switch (activeTab) {
      case "disbursement-summary":
        return (
          <DisbursementSummary
            disburseLoan={disburseLoan}
            handleGenerateLoanSchedule={handleGenerateLoanSchedule}
            handleSaveLoanDetails={handleSaveLoanDetails}
            onCloseModal={onCloseModal}
            first_name={first_name}
            last_name={last_name}
            loan_amount={loan_amount}
            loan_id={loan_id}
            state={state}
            handleOptionSelect={handleOptionSelect}
            loanScheduleResponse={loanScheduleResponse}
            saveLoanDetailsResponse={saveLoanDetailsResponse}
            funderOptions={funderOptions}
            switchState={switchState}
            setSwitchState={setSwitchState}
            sendNewLoanOffer={sendNewLoanOffer}
            triggerNewLoanOffer={triggerNewLoanOffer}
            closeNewLoanOfferTrigger={closeNewLoanOfferTrigger}
            loanOfferState={loanOfferState}
            loan_offers={loanOffer}
            isLoadingLoanOffers={isLoadingLoanOffers}
          />
        );
      case "credit-bureau":
        return (
          <CreditBureauCRC
            first_name={first_name}
            last_name={last_name}
            bvn={bvn}
            borrower_type_value={borrower_type_value}
            rc_number={rc_number}
            loan_id={loan_id}
          />
        );
      case "credit-bureau-check-history":
        return (
          <CreditBureauCheckHistory
            first_name={first_name}
            last_name={last_name}
            borrower_id={borrower_id}
            customer_id={customer_id}
            bvn={bvn}
            borrower_type_value={borrower_type_value}
            rc_number={rc_number}
          />
        );
      case "loan-schedule":
        return (
          <LoanSchedule
            result={loanScheduleResponse}
            setActiveTab={setActiveTab}
            setLoanInfoFromSchedule={setLoanInfoFromSchedule}
          />
        );
      case "direct-debit":
        return (
          <DirectDebit
            properties={properties}
            loan_id={loan_id}
            loan_ref={loan_ref}
            first_name={first_name}
            last_name={last_name}
            state={state}
          />
        );

      case "card":
        return (
          <Card
            dataRecord={properties?.dataRecord}
            first_name={first_name}
            last_name={last_name}
          />
        );

      case "credit-score":
        return (
          <CreditScore
            properties={properties}
            loanInfoFromSchedule={loanInfoFromSchedule}
          />
        );
      case "upload-bank-statement":
        return <UploadBankStatement loanId={loan_id} properties={properties} />;
      case "account-statistics":
        return <AccountStatistics dataRecord={properties?.dataRecord} />;
      case "bank-statement":
        return <BankStatement dataRecord={properties?.dataRecord} />;
      case "bank-statement-analysis":
        return <BankStatementAnalysis dataRecord={properties?.dataRecord} />;
      case "loan-history":
        return <LoanHistory properties={properties} />;
      case "stock-information":
        return <StockData properties={properties} />;
      case "orders":
        return (
          <Orders
            properties={properties}
            setOrderItemList={setOrderItemList}
            setActiveTab={setActiveTab}
          />
        );
      case "order-items":
        return <OrderItems orderItemlist={orderItemlist} />;
      default:
        return null;
    }
  };

  function closeNewLoanOfferTrigger() {
    setTriggerNewLoanOffer(false);
  }

  const handleOptionSelect = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;

    const isAmountApprovedDifferent = state.amountApproved !== loan_amount;
    const isApprovedTenorDifferent =
      state.approvedTenor !== properties?.dataRecord?.loan_tenure;

    const shouldTriggerNewLoanOffer =
      (name === "amountApproved" && value !== loan_amount) ||
      (name === "approvedTenor" &&
        value !== String(properties?.dataRecord?.loan_tenure)) ||
      ([
        "comment",
        "selectedDate",
        "firstRepaymentDate",
        "selectedFunder",
      ].includes(name) &&
        (isAmountApprovedDifferent || isApprovedTenorDifferent));

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Set trigger for new loan offer
    setTriggerNewLoanOffer(shouldTriggerNewLoanOffer);
  };

  const generateLoanSchedule = async (
    loan_id: number,
    amountApproved: number,
    loan_effective_date: string,
    approvedTenor: number,
    firstRepaymentDate: string | null,
  ) => {
    setLoanScheduleResponse({ status: "loading", data: null, error: false });
    const payload = {
      loanId: loan_id,
      principal: Number(amountApproved),
      loanEffectiveDate: loan_effective_date,
      loanTenor: Number(approvedTenor),
      firstRepaymentDate: firstRepaymentDate || null,
    };

    try {
      const response = await ajaxMethods.post(
        `${ajaxEndpoints.GENERATE_PENDING_LOAN_SCHEDULE}`,
        payload,
      );

      if (response?.data?.status === true) {
        setLoanScheduleResponse({
          data: response?.data?.data,
          status: "success",
          error: false,
        });
        setActiveTab("loan-schedule");
        setIsTabClickable(true);
      } else {
        setLoanScheduleResponse({
          data: null,
          status: "error",
          error: true,
        });
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "loanSchedule.tsx",
        },
      });
      setLoanScheduleResponse({
        data: null,
        status: "error",
        error: true,
      });
    }
  };

  const handleGenerateLoanSchedule = async () => {
    await generateLoanSchedule(
      properties?.dataRecord?.loan_id,
      Number(state?.amountApproved),
      state.selectedDate,
      Number(state.approvedTenor),
      state.firstRepaymentDate,
    );
  };

  const getFunderName = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_ALL_FUNDERS)
      .then((response) => {
        let funderList = [] as IFunderOptions[];

        if (response.data.length > 0) {
          funderList = [...response.data];
        }
        const funderOptions = funderList.map((funder, index) => {
          const { funder_id, funder_name } = funder;
          return (
            <option key={index} id={funder_id.toString()} value={funder_id}>
              {funder_name}
            </option>
          );
        });

        setState((prev: IState) => {
          return {
            ...prev,
            funderOptions: funderOptions,
          };
        });
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "Disburse.js",
          },
        });
      });
  };

  const sendNewLoanOffer = async () => {
    const { comment, amountApproved, approvedTenor } = state;

    setloanOfferState((prev) => ({ ...prev, isLoading: true }));

    const payload = {
      loan_ref: properties?.dataRecord.loan_ref,
      offer_amount: amountApproved,
      offer_tenure: approvedTenor,
      comment: comment,
    };

    try {
      const response = await ajaxMethods.post(
        ajaxEndpoints.SEND_NEW_LOAN_OFFER,
        payload,
      );

      if (response.status === 200) {
        queryCache.invalidateQueries([loan_ref, "getLoanOffer"]);
        setTriggerNewLoanOffer(false);
        setloanOfferState((prev) => ({
          ...prev,
          isLoading: false,
          success: true,
        }));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(
        error.data.response.message || "Failed to send new loan offer!",
      );
    } finally {
      setTimeout(() => {
        setloanOfferState((prev) => ({
          ...prev,
          isLoading: false,
          success: false,
        }));
      }, 5000);
    }
  };

  const disburseLoan = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { comment, selectedDate, selectedFunder, amountApproved } = state;

    const { notification, result, loans, resetLoansForDisbursement } =
      properties;

    if (comment && selectedDate && selectedFunder && amountApproved) {
      const params = {
        loan_ref: properties?.dataRecord.loan_ref,
        comment: comment,
        funder_id: Number(selectedFunder),
        loan_effective_date: selectedDate,
        amount_approved: Number(amountApproved),
        creditBureauChecked: switchState,
        approvedTenor: Number(state.approvedTenor) ?? null,
        firstRepaymentDate: state.firstRepaymentDate ?? null,
      };
      const _disbursementConfirm = document.querySelector(
        "#disbursement-confirm",
      ) as HTMLElement;
      _disbursementConfirm.classList.add("hidden");

      const _disbursementLoader = document.querySelector(
        "#disbursement-loader",
      ) as HTMLElement;
      _disbursementLoader.classList.remove("hidden");

      const _disbursementSuccess = document.querySelector(
        "#disbursement-success",
      ) as HTMLElement;

      const _disbursementError = document.querySelector(
        "#disbursement-error",
      ) as HTMLElement;

      ajaxMethods
        .post(ajaxEndpoints.DISBURSE_LOANS, params)
        .then((response) => {
          if (response?.status === 200) {
            _disbursementLoader.classList.add("hidden");
            _disbursementSuccess.classList.remove("hidden");
            result(loans);
            onCloseModal();
            resetLoansForDisbursement();
            handleSwalSuccessAlert(
              response?.data?.message ?? response?.data?.result?.message,
            );
          } else if (response?.status === 400) {
            _disbursementLoader.classList.add("hidden");
            _disbursementError.classList.remove("hidden");
            result(loans);
            onCloseModal();
            resetLoansForDisbursement();
            handleSwalErrorAlert(
              response?.data?.message ?? response?.data?.result?.message,
            );
          } else {
            _disbursementLoader.classList.add("hidden");
            _disbursementError.classList.remove("hidden");
            result(loans);
            onCloseModal();
            resetLoansForDisbursement();
            handleSwalErrorAlert(
              response?.data?.message ?? response?.data?.result?.message,
            );
          }
        })
        .catch((error) => {
          appInsights.trackException({
            exception: error,
            properties: {
              fileName: "Disburse.js",
            },
          });
          _disbursementLoader.classList.add("hidden");
          _disbursementError.classList.remove("hidden");
          result(loans);
          onCloseModal();
          resetLoansForDisbursement();
          handleSwalErrorAlert(
            error.response?.data?.message ??
              "Could not disburse selected loans",
          );
        });
    } else {
      setState((prev) => {
        return {
          ...prev,
          error: true,
        };
      });
    }
  };

  const saveLoanDetails = async (
    loan_id: number,
    amountApproved: number,
    loan_effective_date: string,
    approvedTenor: number,
    firstRepaymentDate: string | null,
    comment: string | null,
  ) => {
    setsaveLoanDetailsResponse({ status: "loading", error: false });
    const payload: any = {
      loanId: loan_id,
      principalAmount: Number(amountApproved),
      loanEffectiveDate: loan_effective_date,
      approvedTenure: approvedTenor,
    };

    if (firstRepaymentDate) {
      payload.firstRepaymentDate = firstRepaymentDate;
    }

    if (comment) {
      payload.comment = comment;
    }

    try {
      const response = await ajaxMethods.post(
        `${ajaxEndpoints.SAVE_SINGLE_LOAN_DETAIL}`,
        payload,
      );

      if (response?.data?.status === true) {
        setsaveLoanDetailsResponse({
          status: "success",
          message: response?.data?.message,
          error: false,
        });
        dispatch(refetchPendingDisbursements());
      } else {
        setsaveLoanDetailsResponse({
          status: "error",
          error: true,
        });
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "DisbursementSummary.tsx",
        },
      });
      setsaveLoanDetailsResponse({
        status: "error",
        message: error?.response?.data?.message ?? error?.message,
        error: true,
      });
    }
  };

  const handleSaveLoanDetails = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    await saveLoanDetails(
      properties?.dataRecord?.loan_id,
      Number(state?.amountApproved),
      state?.selectedDate,
      Number(state?.approvedTenor),
      state?.firstRepaymentDate,
      state?.comment,
    );
  };

  useEffect(() => {
    getFunderName();
  }, []);

  return (
    <React.Fragment>
      <div className="alignToggleTabItems animated fadeInRight m-b-0">
        <ToggleTab
          text="Disbursement Summary"
          id="disbursement-summary"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />

        <ToggleTab
          text="Loan Schedule"
          id="loan-schedule"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isClickable={isTabClickabale}
        />
        <ToggleTab
          text="Direct Debit"
          id="direct-debit"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isClickable={isTabClickabale}
        />
        <ToggleTab
          text="Card"
          id="card"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          // isClickable={isTabClickabale}
        />
        <ToggleTab
          text="Credit Decision"
          id="credit-score"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isClickable={isTabClickabale}
        />
        <ToggleTab
          text="Upload bank statement"
          id="upload-bank-statement"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Credit Bureau"
          id="credit-bureau"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Credit Bureau Check History"
          id="credit-bureau-check-history"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Account Statistics"
          id="account-statistics"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Bank Statement"
          id="bank-statement"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Bank Statement Analysis"
          id="bank-statement-analysis"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Loan History"
          id="loan-history"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ToggleTab
          text="Orders"
          id="orders"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {activeTab === "order-items" && (
          <ToggleTab
            text="Order Items"
            id="order-items"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
        <ToggleTab
          text="Stock Information"
          id="stock-information"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>

      {loanScheduleResponse?.error && (
        <Alert message={"Unable to generate loan schedule"} />
      )}

      {saveLoanDetailsResponse?.error ? (
        <Alert message={`${saveLoanDetailsResponse.message}`} />
      ) : (
        saveLoanDetailsResponse?.status === "success" && (
          <Alert
            type="success"
            message={`${saveLoanDetailsResponse.message}`}
          />
        )
      )}

      <div>{renderContent()}</div>
    </React.Fragment>
  );
};

export default SingleLoanDisbursement;
