import { useEffect } from "react";

import { appInsights } from "../../../../../../config/appInsights";
import { Loader } from "../../../../../../UI/Loaders/Loaders";
import { TFundingWallet } from "../../../../../../custom-hooks/useFundingWallets";
import { formatMoney } from "../../../../../../helpers/formatter";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import useFundingRequests from "../../../../../../custom-hooks/useFundingRequests";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../../../redux/actions/modal";
import { attachClassNameToTag } from "../../../../../../helpers/attachClassNameToTag";

import styles from "./requestFund.module.scss";
import { MixPanel } from "../../../../../../shared/MixPanel/MixPanel";

interface IRequestFund {
  walletInfo: TFundingWallet;
}

const RequestFund = ({ walletInfo }: IRequestFund) => {
  useEffect(() => {
    appInsights.trackPageView({
      name: "RequestFund",
      isLoggedIn: true,
    });

    // prettier-ignore
    MixPanel.track_pageview({ "page": "RequestFund" });
  }, []);

  const dispatch = useDispatch();

  const handleDisplayApprovalStatusModal = (
    loanReference: string,
    aggregator: string,
  ) => {
    dispatch(
      showModal({
        modalIdentifier: "fundingrequestapprovalstatus",
        dataRecord: { loanReference, aggregator },
      }),
    );
  };

  const {
    data: fundingRequests,
    status,
    error,
  } = useFundingRequests({
    fundingWalletId: walletInfo?.walletId,
  });

  let RenderedComponent = null;
  if (!fundingRequests && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Funding Requests
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Funding Requests..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              funding requests, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (fundingRequests &&
        fundingRequests.data &&
        fundingRequests.data.length === 0) ||
      (fundingRequests && !fundingRequests.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no funding
              requests
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <>
          <MDBTable
            responsive
            minimal="true"
            striped={false}
            bordered
            hover
            id="table-to-xls"
            scrollY
          >
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Duration Type</th>
                <th>Duration</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {fundingRequests &&
                fundingRequests.data.map((data, _id: number) => {
                  const {
                    amount,
                    dateTimeCreated,
                    status,
                    tenorType,
                    tenor,
                    loanReference,
                    aggregator,
                  } = data;
                  return (
                    <tr key={_id}>
                      <td>{_id + 1}</td>
                      {/* <td>
                        {" "}
                        {(searchQuery?.Page - 1) * searchQuery?.PageSize +
                          (_id + 1)}
                      </td> */}
                      <td>{dateTimeCreated.split("T")[0]}</td>
                      <td>{formatMoney(amount)}</td>
                      <td>{tenorType}</td>
                      <td>{tenor}</td>
                      <td>
                        {" "}
                        <span className={attachClassNameToTag(status)}>
                          {attachClassNameToTag(status)}
                        </span>
                      </td>
                      <td>
                        <div className="dropdown">
                          <button
                            className="btn zoom-element btn-slim dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            disabled={status.toLowerCase() !== "approved"}
                          ></button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <span
                              className=" cursor-pointer  dropdown-item"
                              onClick={() => {
                                dispatch(
                                  showModal({
                                    modalIdentifier:
                                      "fundingrequestrepaymentschedule",
                                    dataRecord: { loanReference, aggregator },
                                  }),
                                );
                              }}
                            >
                              View Repayment Schedule
                            </span>
                            <span
                              className=" cursor-pointer  dropdown-item"
                              onClick={() => {
                                handleDisplayApprovalStatusModal(
                                  loanReference,
                                  aggregator,
                                );
                              }}
                            >
                              Approval Status
                            </span>
                          </div>
                        </div>
                      </td>
                      {/* <td className="w-100">
                        <div className="row">
                          <span
                            className="color-mid-blue cursor-pointer font-weight-bold"
                            onClick={() => {
                              dispatch(
                                showModal({
                                  modalIdentifier:
                                    "fundingrequestrepaymentschedule",
                                  dataRecord: { reference, aggregator },
                                })
                              );
                            }}
                          >
                            View
                          </span>
                          <span
                            className="color-mid-blue cursor-pointer font-weight-bold ml-3"
                            onClick={() => {
                              dispatch(
                                showModal({
                                  modalIdentifier:
                                    "fundingrequestapprovalstatus",
                                  dataRecord: { reference, aggregator },
                                })
                              );
                            }}
                          >
                            Approval Status
                          </span>
                        </div>
                      </td> */}
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
        </>
      );
    }
  }

  return (
    <div className={styles.RequestFund}>
      <div className={styles.RequestFundAction}>
        <button
          type="button"
          className="btn advancly-btn btn-md m-0"
          onClick={() => {
            dispatch(
              showModal({
                modalIdentifier: "fundingrequest",
                dataRecord: walletInfo,
              }),
            );
          }}
          disabled={walletInfo.fundingPartner.toLowerCase() === "wema"}
        >
          Request Fund
        </button>
      </div>

      <div className={styles.WalletTransactions}>{RenderedComponent}</div>
    </div>
  );
};

export default RequestFund;
