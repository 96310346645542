import React, { useEffect, useState } from "react";

// Redux
import { connect, useDispatch } from "react-redux";
import { setToast } from "../../../../redux/actions/toast";
import { showModal } from "../../../../redux/actions/modal";

// Ajax Methods
import ajaxMethods from "../../../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";

import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

// UI Components
import { ErrorLoader } from "../../../../UI/Loaders/Loaders";
import { formatMoney } from "../../../../helpers/formatter";
import { appInsights } from "../../../../config/appInsights";
import SingleLoanDisbursement from "./SingleLoanDisbursement";

import "./DisburseModal.css";

type TState = {
  disbursementTag: string;
  comment: string;
  error: boolean;
  funderOptions: any[];
  selectedFunder: string;
  selectedDate: string;
  failedApprovedBatchLoans: any[];
  successfulApprovedBatchLoans: any[];
  failedRejectedBatchLoans: any[];
  successfulRejectedBatchLoans: any[];
  amountApproved: number;
  errorMessage: any;
};

const Disburse = (props: any) => {
  const [state, setState] = useState<TState>({
    disbursementTag: "",
    comment: "",
    error: false,
    funderOptions: [],
    selectedFunder: "",
    selectedDate: "",
    failedApprovedBatchLoans: [],
    successfulApprovedBatchLoans: [],
    failedRejectedBatchLoans: [],
    successfulRejectedBatchLoans: [],
    amountApproved: props?.properties?.dataRecord?.loan_amount,
    errorMessage: {},
  });

  const dispatch = useDispatch();

  useEffect(() => {
    getFunderName();
  }, []);

  const disburseBatchLoan = (e: any) => {
    e.preventDefault();
    const { comment, selectedDate, selectedFunder } = state;

    if (comment && selectedDate && selectedFunder) {
      const { loans, result, resetLoansForDisbursement } = props.properties;
      const params = {
        loan_refs: loans,
        comment: comment,
        funder_id: Number(selectedFunder),
        loan_effective_date: selectedDate,
      };

      const disbursementConfirm = document.querySelector(
        "#disbursement-confirm"
      );
      disbursementConfirm?.classList.add("hidden");

      const disbursementLoader = document.querySelector("#disbursement-loader");
      disbursementLoader?.classList.remove("hidden");

      const disbursementError = document.querySelector("#disbursement-error");

      ajaxMethods
        .post(ajaxEndpoints.DISBURSE_BATCH_LOANS, params)
        .then((response) => {
          // let message = "";
          if (response.status === 200) {
            disbursementLoader?.classList.add("hidden");

            const disbursementSuccess = document.querySelector(
              "#disbursement-success"
            );
            disbursementSuccess?.classList.remove("hidden");

            if (response?.data?.data?.success.length > 0) {
              setState((prev) => {
                return {
                  ...prev,
                  successfulApprovedBatchLoans: response.data.data.success,
                };
              });
            }
            if (response?.data?.data?.failure.length > 0) {
              setState((prev) => {
                return {
                  ...prev,
                  failedApprovedBatchLoans: response.data.data.failure,
                };
              });
            }
            dispatch(result(loans));
            resetLoansForDisbursement();
          } else {
            disbursementLoader?.classList.add("hidden");
            disbursementError?.classList.remove("hidden");

            dispatch(result(loans));

            resetLoansForDisbursement();
            setState((prev) => {
              return {
                ...prev,
                errorMessage: {
                  title: "Warning",
                  message: response?.data?.message,
                },
              };
            });
            setTimeout(() => {
              props.setToast("warning", response?.data?.message);
              return props.onCloseModal();
            }, 3000);
          }
        })
        .catch((error) => {
          appInsights.trackException({
            exception: error,
            properties: {
              fileName: "Disburse.js",
            },
          });
          disbursementLoader?.classList.add("hidden");
          disbursementError?.classList.remove("hidden");

          dispatch(result(loans));
          resetLoansForDisbursement();
          setState((prev) => {
            return {
              ...prev,
              errorMessage: {
                title: "Error",
                message: error?.response?.data?.message,
              },
            };
          });
          setTimeout(() => {
            props.setToast("warning", error?.response?.data?.message);
            return props.onCloseModal();
          }, 3000);
        });
    }
  };

  const declineBatchLoan = (e: any) => {
    e.preventDefault();
    const { comment } = state;

    if (comment) {
      const { loans, result, resetLoansForDisbursement } = props.properties;
      const params = {
        loan_refs: loans,
        comment: comment,
      };

      const disbursementConfirm = document.querySelector(
        "#disbursement-confirm"
      );
      disbursementConfirm?.classList.add("hidden");

      const disbursementLoader = document.querySelector("#disbursement-loader");
      disbursementLoader?.classList.remove("hidden");

      const disbursementError = document.querySelector("#disbursement-error");

      ajaxMethods
        .post(ajaxEndpoints.REJECT_BATCH_LOANS, params)
        .then((response) => {
          // let message = "";
          if (response.status === 200) {
            disbursementLoader?.classList.add("hidden");

            const disbursementSuccess = document.querySelector(
              "#disbursement-success"
            );
            disbursementSuccess?.classList.remove("hidden");

            if (response?.data?.data?.success?.length > 0) {
              setState((prev) => {
                return {
                  ...prev,
                  successfulRejectedBatchLoans: response.data.data.success,
                };
              });
            }
            if (response?.data?.data?.failure?.length > 0) {
              setState((prev) => {
                return {
                  ...prev,
                  failedRejectedBatchLoans: response.data.data.failure,
                };
              });
            }
            dispatch(result(loans));
            resetLoansForDisbursement();
          } else {
            disbursementLoader?.classList.add("hidden");
            disbursementError?.classList.remove("hidden");

            dispatch(result(loans));
            resetLoansForDisbursement();
            setTimeout(() => {
              props.setToast(
                "warning",
                "Unable to connect to Advancly services"
              );
              return props.onCloseModal();
            }, 3000);
          }
        })
        .catch((error) => {
          appInsights.trackException({
            exception: error,
            properties: {
              fileName: "Disburse.tsx",
            },
          });

          disbursementLoader?.classList.add("hidden");
          disbursementError?.classList.remove("hidden");

          dispatch(result(loans));
          resetLoansForDisbursement();
          setTimeout(() => {
            props.setToast(
              "warning",
              "Unable to connect to Advancly services."
            );
            return props.onCloseModal();
          }, 3000);
        });
    }
  };
  //fetch all funders
  const getFunderName = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_ALL_FUNDERS)
      .then((response) => {
        let funderList = [] as any[];

        if (response.data.length > 0) {
          funderList = [...response.data];
        }
        const funderOptions = funderList.map((funder, index) => {
          const { funder_id, funder_name } = funder;
          return (
            <option key={index} id={funder_id} value={funder_id}>
              {funder_name}
            </option>
          );
        });

        setState((prev) => {
          return {
            ...prev,
            funderOptions: funderOptions,
          };
        });
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "Disburse.js",
          },
        });
      });
  };

  const handleOptionSelect = (e: any) => {
    setState((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        error: false,
      };
    });
  };

  const { subModal } = props.properties;
  const { comment, error, selectedFunder, funderOptions, selectedDate } = state;
  let renderSuccessfulApprovedData,
    renderApprovedFailedData,
    renderSuccessfulRejectedData,
    renderRejectedFailedData;
  const {
    failedApprovedBatchLoans,
    successfulApprovedBatchLoans,
    failedRejectedBatchLoans,
    successfulRejectedBatchLoans,
  } = state;

  if (successfulApprovedBatchLoans || failedApprovedBatchLoans) {
    if (successfulApprovedBatchLoans) {
      renderSuccessfulApprovedData = (
        <div>
          <p className="text-success">See successful approved loans below: </p>
          <MDBTable responsive minimal="true" bordered hover>
            <MDBTableHead>
              <tr>
                {/* <th style={{ width: 50 }}>S/N</th> */}
                <th style={{ width: 50 }}>Loan Id</th>
                <th>Message</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {successfulApprovedBatchLoans.map((successfulBatchLoan: any) => {
                return (
                  <tr>
                    {/* <td style={{ width: 50 }}>{successfulBatchLoan.id}</td> */}
                    <td style={{ width: 50 }}>
                      {successfulBatchLoan?.data?.data?.entityTypeId}
                    </td>
                    <td>{successfulBatchLoan?.data?.data?.message}</td>
                  </tr>
                );
              })}
            </MDBTableBody>
          </MDBTable>
        </div>
      );
    }
    if (failedApprovedBatchLoans.length > 0) {
      renderApprovedFailedData = (
        <div>
          <p className="text-danger">
            See failed approved loan reasons below:{" "}
          </p>
          <MDBTable responsive minimal="true" bordered hover>
            <MDBTableHead>
              <tr>
                {/* <th style={{ width: 50 }}>S/N</th> */}
                <th style={{ width: 50 }}>Loan Id</th>
                <th>Message</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {failedApprovedBatchLoans.map((failedBatchLoan) => {
                return (
                  <tr>
                    {/* <td style={{ width: 50 }}>{failedBatchLoan.id}</td> */}
                    <td style={{ width: 50 }}>
                      {failedBatchLoan?.data?.data?.data?.entityTypeId}
                    </td>
                    <td>{failedBatchLoan?.data?.data?.message}</td>
                  </tr>
                );
              })}
            </MDBTableBody>
          </MDBTable>
        </div>
      );
    }
  } else if (successfulRejectedBatchLoans || failedRejectedBatchLoans) {
    if (successfulRejectedBatchLoans) {
      renderSuccessfulRejectedData = (
        <div className="m-b-2 m-t-1">
          <p className="text-success">See successful rejected loans below: </p>
          <MDBTable responsive minimal="true" bordered hover>
            <MDBTableHead>
              <tr>
                {/* <th style={{ width: 50 }}>S/N</th> */}
                <th style={{ width: 50 }}>Loan ref</th>
                <th>Message</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {successfulRejectedBatchLoans.map(
                (successfulRejectedBatchLoan) => {
                  return (
                    <tr>
                      {/* <td style={{ width: 50 }}>
                        {successfulRejectedBatchLoan.id}
                      </td> */}
                      <td style={{ width: 50 }}>
                        {successfulRejectedBatchLoan?.data}
                      </td>
                      <td>{successfulRejectedBatchLoan?.message}</td>
                    </tr>
                  );
                }
              )}
            </MDBTableBody>
          </MDBTable>
        </div>
      );
    }
    if (failedRejectedBatchLoans) {
      renderRejectedFailedData = (
        <div className="m-b-2 m-t-1">
          <p className="text-danger">
            See failed rejected loans reasons below:{" "}
          </p>
          <MDBTable responsive minimal="true" bordered hover>
            <MDBTableHead>
              <tr>
                {/* <th style={{ width: 50 }}>S/N</th> */}
                <th style={{ width: 50 }}>Loan ref</th>
                <th>Message</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {failedRejectedBatchLoans.map((failedRejectedBatchLoan) => {
                return (
                  <tr>
                    {/* <td style={{ width: 50 }}>
                      {failedRejectedBatchLoan.id}
                    </td> */}
                    <td style={{ width: 50 }}>
                      {failedRejectedBatchLoan?.data}
                    </td>
                    <td>{failedRejectedBatchLoan?.message}</td>
                  </tr>
                );
              })}
            </MDBTableBody>
          </MDBTable>
        </div>
      );
    }
  }

  // console.log("DT:", props.properties.dataRecord)

  return (
    <div className="Modal__dialog lg-modal animated slideInDown">
      <div
        className="LoanModal Modal__content"
        style={{ minHeight: "85vh", margin: "0 auto" }}
      >
        <div className="Modal__body" style={{ overflow: "hidden" }}>
          {(() => {
            switch (subModal) {
              case "single":
                // Get data from component props
                const {
                  borrower_details: {
                    // borrower_id,
                    // available_balance,
                    // debt_balance,
                    first_name,
                    last_name,
                    borrower_id,
                    bvn,
                    borrower_type_value,
                    rc_number,
                    customer_id,
                  },
                  loan_amount,
                  loan_id,
                  loan_ref,
                } = props.properties.dataRecord;

                return (
                  <SingleLoanDisbursement
                    first_name={first_name}
                    last_name={last_name}
                    loan_amount={loan_amount}
                    loan_id={loan_id}
                    loan_ref={loan_ref}
                    onCloseModal={props.onCloseModal}
                    properties={props.properties}
                    funderOptions={state.funderOptions}
                    borrower_id={borrower_id}
                    bvn={bvn}
                    borrower_type_value={borrower_type_value}
                    rc_number={rc_number}
                    customer_id={customer_id}
                  />
                );

              case "multiple":
                const { loans, totalAmount } = props.properties;

                return (
                  <React.Fragment>
                    <h3 className="Modal__title p-b-1">Bulk Loans Summary</h3>

                    {/* 1. Confirm Disbursement*/}
                    <div id="disbursement-confirm">
                      <div className="m-b-6 m-r-6">
                        <p>
                          You have selected {loans.length} loans for approval
                          with reference{" "}
                          {Object.keys(loans)
                            .map(function (loan) {
                              return loans[loan];
                            })
                            .join(", ")}{" "}
                          .
                        </p>
                        <p>Total Amount is {formatMoney(totalAmount)} .</p>
                        <p>
                          Are you sure you want to disburse/decline the
                          following loans?
                        </p>
                        <form>
                          {error && (
                            <p className="text-danger">Field cannot be blank</p>
                          )}
                          <label style={{ width: "100%" }}>
                            Comment
                            <input
                              className="form-control"
                              name="comment"
                              value={comment}
                              onChange={(e) => handleOptionSelect(e)}
                            />
                          </label>
                          <label style={{ width: "100%", marginTop: "10px" }}>
                            Funder
                            <select
                              className="round user__form form-control"
                              name="selectedFunder"
                              id="selectedFunder"
                              value={selectedFunder}
                              onChange={(e) => handleOptionSelect(e)}
                            >
                              <option selected></option>
                              {funderOptions}
                            </select>
                          </label>

                          <label style={{ width: "100%", marginTop: "10px" }}>
                            Effective Date
                            <input
                              className="round user__form form-control"
                              name="selectedDate"
                              type="date"
                              id="selectedDate"
                              value={selectedDate}
                              onChange={(e) => handleOptionSelect(e)}
                            />
                          </label>
                          <div className="Form__action-buttons m-t-2">
                            <button
                              type="submit"
                              data-id={loan_id}
                              onClick={disburseBatchLoan}
                              disabled={
                                !(comment && selectedFunder && selectedDate)
                              }
                              className="btn advancly-btn m-0 mr-auto"
                            >
                              <i className="fa fa-check-circle" /> Approve
                            </button>
                            <button
                              type="submit"
                              data-id={loan_id}
                              onClick={declineBatchLoan}
                              disabled={!comment}
                              className="btn advancly-dimred-btn m-0 "
                            >
                              <i className="fa fa-times-circle" /> Decline
                            </button>
                            <button
                              type="button"
                              onClick={props.onCloseModal}
                              className="btn advancly-btn m-0 ml-auto"
                            >
                              <i className="fa fa-times" /> Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>

                    {/* 2. Disbursement Loader*/}
                    <div
                      id="disbursement-loader"
                      className="hidden loading-overlay speed-1x fadeInRight"
                    >
                      <div className=" form-loader form-loader-login verify">
                        <div className="centerLoader">
                          <div className="loading" id="adv-loader-alt" />
                          <div className="center-txt webapp-ripple-text">
                            <i className="notifier fas fa-user-cog" />
                            Processing Disbursement ...
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* 3. Disbursement Success Message*/}
                    <div
                      id="disbursement-success"
                      className="hidden speed-1x fadeInRight"
                    >
                      {(successfulApprovedBatchLoans ||
                        failedApprovedBatchLoans) && (
                        <div className="m-b-3">
                          <div>
                            <div className="row m-t-2 grid__padding">
                              {successfulApprovedBatchLoans &&
                              successfulApprovedBatchLoans.length > 0 ? (
                                <div> {renderSuccessfulApprovedData} </div>
                              ) : (
                                "No Successful Approval."
                              )}
                            </div>
                            <div className="row m-t-2 grid__padding">
                              {failedApprovedBatchLoans &&
                              failedApprovedBatchLoans.length > 0 ? (
                                <div>{renderApprovedFailedData}</div>
                              ) : (
                                "No Failed Approval."
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {(successfulRejectedBatchLoans ||
                        failedRejectedBatchLoans) && (
                        <div className="m-b-3">
                          <div>
                            <div className="row m-t-2 grid__padding">
                              {successfulRejectedBatchLoans &&
                              successfulRejectedBatchLoans.length > 0 ? (
                                <div>{renderSuccessfulRejectedData}</div>
                              ) : (
                                "No Loan was successfully Rejected."
                              )}
                            </div>
                            <div className="row m-t-2 grid__padding">
                              {failedRejectedBatchLoans &&
                              failedRejectedBatchLoans.length > 0 ? (
                                <div>{renderRejectedFailedData}</div>
                              ) : (
                                "No Failed Rejected Loan."
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="Form__action-buttons m-t-2"></div>
                      <button
                        type="button"
                        onClick={props.onCloseModal}
                        className="btn zoom-element btn login-btn m-0"
                      >
                        <i className="fa fa-times-circle" /> Close
                      </button>
                    </div>

                    {/* 4. Disbursement Error */}
                    <div
                      id="disbursement-error"
                      className="hidden loading-overlay speed-1x fadeInRight"
                    >
                      <ErrorLoader
                        icon="fas fa-wifi"
                        text={state.errorMessage?.title}
                        subText={state.errorMessage?.message}
                      />
                    </div>
                  </React.Fragment>
                );

              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const {
    loansReducer: {
      disbursements: { records, disbursementSize, disbursementPageNumber },
    },
  } = state;
  return {
    records,
    disbursementSize,
    disbursementPageNumber,
  };
};

export default connect(mapStateToProps, {
  setToast,
  showModal,
})(Disburse);
