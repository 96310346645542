import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  LoanDetails,
  LoanTransactionDetails,
} from "../../../../../interfaces/loan";
import { formatMoney } from "../../../../../helpers/formatter";
import moment from "moment";
import {
  loanStatementColumn,
  reOrderTableData,
} from "../../../../../redux/actions/loans";
import { MDBDataTable } from "mdbreact";
import { appInsights } from "../../../../../config/appInsights";
import { getTokenFromLocalStorage } from "../../../../../helpers/localStorage";
import { BASE_URL } from "../../../../../appConstants";
import { useHistory } from "react-router-dom";
import {
  DOWNLOAD_LOAN_STATEMENT,
  GET_LOAN_STATEMENT_DATA,
} from "../../../../../api/ajax-endpoints";
import { loanStatementData } from "../../../../../mocks/loans";
import { getData } from "../../../../../newApis/apiMethods";
import { MixPanel } from "../../../../../shared/MixPanel/MixPanel";

interface IProps {
  loanRef: string;
}

const LoanStatement = ({ loanRef }: IProps) => {
  const history = useHistory();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<
    "pdf" | "docx" | "xlsx" | string
  >("");
  const [downloading, setDownloading] = useState(false);
  const [showAccruals, setShowAccruals] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [allTransactions, setAllTransactions] = useState<
    LoanTransactionDetails[]
  >([]);
  const [loanDetails, setLoanDetails] = useState<LoanDetails | null>(null);
  const [loanTransactions, setLoanTransactions] = useState<
    LoanTransactionDetails[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleOptionSelect = (option: string) => {
    setSelectedOption(option);
    setDropdownOpen(false);
  };

  const toggleAccruals = (showHide: boolean) => {
    setShowAccruals(showHide);
    if (showHide) {
      setLoanTransactions(allTransactions);
    } else {
      const transactions = allTransactions.filter(
        (value) => value.transactionType !== "Accrual",
      );
      setLoanTransactions(transactions);
    }
  };

  useEffect(() => {
    handleGetLoanData();

    // prettier-ignore
    MixPanel.track_pageview({ "page": "Core Banking - Loan Statement" });
  }, []);

  useEffect(() => {
    if (selectedOption) {
      setSelectedOption("");
    }
  }, [selectedOption]);

  useEffect(() => {
    if (allTransactions?.length > 0) {
      toggleAccruals(false);
    }
  }, [allTransactions]);

  const handleGetLoanData = async () => {
    setIsLoading(true);
    try {
      const response = await getData(
        `${GET_LOAN_STATEMENT_DATA}?loan_ref=${loanRef}`,
      );
      setAllTransactions(response.data?.transactions);
      setLoanDetails(response.data?.loanDetails);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadStatement = async () => {
    console.log(`Downloading ${selectedOption}`);
    setDownloading(true);
    let token = getTokenFromLocalStorage();
    fetch(
      BASE_URL +
        `${DOWNLOAD_LOAN_STATEMENT}?loan_ref=${loanRef}&format=${selectedOption}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Token " + token,
        },
        // body: JSON.stringify(data)
      },
    )
      .then((response) => {
        setDownloading(false);

        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Loan Statement.${selectedOption}`);
            link.click();
            // link.parentNode.removeChild(link);
          });
        }

        if (response.status === 400) {
          // response.json()
          // .then(res => {
          //     this.setState({ error: res.message });
          // })
        }

        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          history.push("/login");
        }

        if (response.status >= 500) {
          // this.setState({ error: "Something went wrong. Please check your internet connection and try again" });
        }
      })
      .catch((error) => {
        setErrorOccurred(true);
        setTimeout(() => {
          setErrorOccurred(false);
        }, 3000);
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "LoanStatement.tsx",
          },
        });
        // this.setState({ loading: false, error: "Something went wrong. Please check your internet connection and try again" });
        setDownloading(false);
      });
  };

  useEffect(() => {
    if (selectedOption) {
      handleDownloadStatement();
    }
  }, [selectedOption]);

  const LoanStatementOptions = () => {
    return (
      <div className="d-flex justify-content-between w-100">
        <div>
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            disabled={downloading}
          >
            <DropdownToggle caret>
              {downloading
                ? "Downloading....."
                : selectedOption
                  ? selectedOption
                  : "Select a download format"}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleOptionSelect("pdf")}>
                ADOBE PDF
              </DropdownItem>
              <DropdownItem onClick={() => handleOptionSelect("docx")}>
                MICROSOFT WORD
              </DropdownItem>
              <DropdownItem onClick={() => handleOptionSelect("xlsx")}>
                MICROSOFT EXCEL
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div>
          <button
            className="btn btn-secondary"
            onClick={() => toggleAccruals(!showAccruals)}
          >
            {`${showAccruals ? "Hide" : "Show"} Accruals`}
          </button>
        </div>
      </div>
    );
  };

  const formattedLoanStatementData = loanTransactions.map((item) => ({
    ...item,
    date: moment(item.date).format("DD-MM-YYYY"),
  }));

  return (
    <div>
      {errorOccurred && (
        <p className="alert alert-danger col-md-6">
          An error occurred while downloading statement, please try again
        </p>
      )}
      <LoanStatementOptions />
      <br />
      <div className="row">
        <div className="col-md-6 mb-2">
          Loan Reference Number -
          <span className="font-weight-bold ml-2">{loanRef}</span>
        </div>
        <div className="col-md-6 mb-2 text-right">
          Customer -
          <span className="font-weight-bold ml-2">
            {loanDetails?.customerName}
          </span>
        </div>
        <div className="col-md-6 mb-2">
          Currency -
          <span className="font-weight-bold ml-2">
            {loanDetails?.currencyCode}
          </span>
        </div>
        <div className="col-md-6 mb-2 text-right">
          Product -
          <span className="font-weight-bold ml-2">
            {loanDetails?.productName}
          </span>
        </div>
        <div className="col-md-6 mb-2">
          Interest Rate -
          <span className="font-weight-bold ml-2">
            {loanDetails?.interestRate}
          </span>
        </div>
        <div className="col-md-6 mb-2 text-right">
          Principal -
          <span className="font-weight-bold ml-2">
            {formatMoney(
              loanDetails?.disbursedAmount,
              loanDetails?.currencyCode,
            )}
          </span>
        </div>
        <div className="col-md-6 mb-2">
          Outstanding Principal -
          <span className="font-weight-bold ml-2">
            {formatMoney(
              loanDetails?.outstandingPrincipal,
              loanDetails?.currencyCode,
            )}
          </span>
        </div>
        <div className="col-md-6 mb-2 text-right">
          Outstanding Interest -
          <span className="font-weight-bold ml-2">
            {formatMoney(
              loanDetails?.outstandingInterest,
              loanDetails?.currencyCode,
            )}
          </span>
        </div>

        <div className="col-md-6 mb-2">
          Interest Overdue -
          <span className="font-weight-bold ml-2">
            {formatMoney(
              loanDetails?.interestOverdue,
              loanDetails?.currencyCode,
            )}
          </span>
        </div>
        <div className="col-md-6 mb-2 text-right">
          Principal Overdue -
          <span className="font-weight-bold ml-2">
            {formatMoney(
              loanDetails?.principalOverdue,
              loanDetails?.currencyCode,
            )}
          </span>
        </div>

        <div className="col-md-6 mb-2">
          Delinquent Amount -
          <span className="font-weight-bold ml-2">
            {formatMoney(
              loanDetails?.delinquentAmount,
              loanDetails?.currencyCode,
            )}
          </span>
        </div>
        <div className="col-md-6 mb-2 text-right">
          FeeCharges Overdue -
          <span className="font-weight-bold ml-2">
            {formatMoney(
              loanDetails?.feeChargesOverdue,
              loanDetails?.currencyCode,
            )}
          </span>
        </div>

        <div className="col-md-6 mb-2">
          Start Date -
          <span className="font-weight-bold ml-2">
            {moment(loanDetails?.effectiveDate).format("ll")}
          </span>
        </div>
        <div className="col-md-6 mb-2 text-right">
          Maturity Date -
          <span className="font-weight-bold ml-2">
            {moment(loanDetails?.maturityDate).format("ll")}
          </span>
        </div>
        <div className="col-md-6 mb-2">
          Loan Status -
          <span className="font-weight-bold ml-2">
            {loanDetails?.loanStatus?.toUpperCase()}
          </span>
        </div>
        <div className="col-md-6 mb-2 text-right">
          Payment Frequency -
          <span className="font-weight-bold ml-2">
            {loanDetails?.repaymentFrequency?.toUpperCase()}
          </span>
        </div>

        <div className="col-md-6 mb-2">
          Penalty Charges Overdue -
          <span className="font-weight-bold ml-2">
            {formatMoney(
              loanDetails?.penaltyChargesOverdue,
              loanDetails?.currencyCode,
            )}
          </span>
        </div>

        <div className="col-md-6 mb-2 text-right">
          Interest Payment Type -
          <span className="font-weight-bold ml-2">
            {loanDetails?.interestPaymentType?.toUpperCase()}
          </span>
        </div>
      </div>
      <br />
      <div>
        {isLoading ? (
          <p className="text-center font-weight-bold">Loading....</p>
        ) : (
          <MDBDataTable
            responsive
            minimal="true"
            striped
            bordered
            hover
            // data={loanTransactionData}
            data={{
              columns: loanStatementColumn,
              rows: formattedLoanStatementData,
            }}
            searching={false}
          />
        )}
      </div>
      <br />
    </div>
  );
};

export default LoanStatement;
