import { Fragment, useEffect, useState } from "react";
import { useCustomerBankDetails } from "../../../../custom-hooks/useCustomerBankDetails";
import { formatMoney } from "../../../../helpers/formatter";
import Loader from "../../../../NewComponents/Loader/Loader.component";
import Paginator from "../../../Pagination/Pagination.component";
import CustomSearchDropDown from "../../../CustomHTMLElements/CustomSearchDropDown/CustomSearchableDropdown";
import { TBankAccountDetails } from "../../../../modals/Modals/Loans/Disburse/CreditAnalysis/type";
import useBankAccountDetails from "../../../../custom-hooks/useBankAccountDetails";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";

const CustomerBankStatementData = ({ customerId }: any) => {
  const [count, setCount] = useState(null);

  const [searchQuery, setSearchQuery] = useState({
    CustomerId: customerId,
    Page: 1,
    PageSize: 20,
    AccountNumber: "",
  });
  const [inputSearch, setInputSearch] = useState({
    DateFrom: "",
    DateTo: "",
  });

  useEffect(() => {
    // prettier-ignore
    MixPanel.track_pageview({ "page": "Bank Statements" });
  }, []);

  const {
    data: bankStatementDetailsData,
    status,
    error,
  } = useCustomerBankDetails(searchQuery);
  if (bankStatementDetailsData) {
    if (count === null) {
      setCount(bankStatementDetailsData?.totalCount);
    }
  }

  const { data: bankAccountDetails } = useBankAccountDetails(customerId);

  const cleanupBankAccountList = (
    _bankAccountDetails: TBankAccountDetails[] | undefined,
  ) => {
    if (_bankAccountDetails && _bankAccountDetails.length > 0) {
      let result = _bankAccountDetails.map((_item) => {
        return {
          label: `${_item?.accountNo}/${_item?.accountName}`,
          value: _item?.accountNo,
        };
      });

      return result;
    }

    return [];
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setInputSearch((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeBankAccount = (data: any) => {
    setInputSearch((prev) => {
      return {
        ...prev,
        AccountNumber: data?.value,
      };
    });
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    setSearchQuery((prev) => {
      return {
        ...prev,
        ...inputSearch,
      };
    });
  };

  let RenderedComponent = null;
  if (!bankStatementDetailsData && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Customer Bank
            Statement Data
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    //@ts-ignore
    RenderedComponent = <Loader centered={true} text="Loading Repayment..." />;
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Bank statement, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (bankStatementDetailsData &&
        bankStatementDetailsData?.transactions &&
        bankStatementDetailsData?.transactions.length === 0) ||
      (bankStatementDetailsData && !bankStatementDetailsData?.transactions)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no requests.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Transaction ID</th>
                  <th>Transaction Type</th>
                  <th>Transaction Date</th>
                  <th>Bank Name</th>
                  <th>Currency</th>
                  <th>Amount</th>
                  <th>Balance</th>
                  <th>Narration</th>
                </tr>
              </thead>
              <tbody>
                {bankStatementDetailsData?.transactions
                  // ?.slice(
                  //   (pageNumber - 1) * size,
                  //   size * (pageNumber - 1) + size
                  // )
                  .map((data: any, id: number) => {
                    const {
                      transactionId,
                      transactionType,
                      //   type,
                      date,
                      narration,
                      amount,
                      balance,
                      currency,
                    } = data;
                    return (
                      <tr key={id}>
                        <td>{id + 1}</td>
                        <td>{transactionId}</td>
                        <td>{transactionType}</td>
                        <td>{date?.substring(0, 10)}</td>
                        <td>{bankStatementDetailsData?.bankName}</td>
                        <td>{currency}</td>
                        <td>{formatMoney(amount)}</td>
                        <td>{formatMoney(balance)}</td>
                        <td>{narration}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  return (
    <Fragment>
      <div className="dataTables_wrapper">
        <div
          id="activeEcosystem"
          className="Disbursement table-view animated speed-1x fadeInRight"
        >
          <div className="d-flex justify-content-between">
            <div className="form-group mr-3 ">
              <div className="input-group ">
                <label className="mr-2">From </label>
                <input
                  type="date"
                  name="DateFrom"
                  className="form-control"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="form-group  mr-3">
              <div className="input-group">
                <label className="mr-2">To </label>
                <input
                  type="date"
                  className="form-control"
                  name="DateTo"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>

            <div>
              <label htmlFor="table-fetch" className="form-group">
                No. of results:{" "}
                <select
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="PageSize"
                  id="table-fetch"
                  className="table-fetch-select"
                  value={searchQuery?.PageSize}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </label>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-center">
            <div
              style={{
                width: "15rem",
                paddingTop: "1.1rem",
              }}
            >
              <CustomSearchDropDown
                label="Select Aggregator"
                defaultOptions={cleanupBankAccountList(bankAccountDetails)}
                onChange={handleChangeBankAccount}
                isShowLabel={false}
                inputHeight={40}
                isSearchable={false}
              />
            </div>
            <div>
              <button
                type="button"
                className="btn advancly-btn m-3 btn-md"
                disabled={status === "loading"}
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>

          {/*Datatable || Loader || Error Message*/}
          {RenderedComponent}
          <Paginator
            size={searchQuery.PageSize}
            page={searchQuery.Page}
            count={count}
            changeCurrentPage={(currentPage: any) =>
              setSearchQuery((prev) => ({ ...prev, Page: currentPage + 1 }))
            }
          />
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerBankStatementData;
