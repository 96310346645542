import { ChangeEvent, useEffect, useState } from "react";
import CustomSelectDropdown from "../../../../CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../../../../Pagination/Pagination.component";
import { Loader } from "../../../../../UI/Loaders/Loaders";
import { withRouter } from "react-router-dom";
import { showModal } from "../../../../../redux/actions/modal";
import { connect, useDispatch } from "react-redux";
// import useAllAggregatorProducts from "../../../../../custom-hooks/useAllAggregatorProducts";
import useBatchedLoansDetails from "../../../../../custom-hooks/useBatchedLoansDetails";
import useAllAggregatorsTyped from "../../../../../custom-hooks/useAllAggregatorsTyped";
import { MixPanel } from "../../../../../shared/MixPanel/MixPanel";

const BatchedDetails = () => {
  const [count, setCount] = useState(null);
  const [state, setState] = useState({
    pageNumber: 1,
    size: 20,
    disbursed: "",
    startDate: "",
    endDate: "",
    aggregatorId: "",
  });
  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 20,
  });

  const dispatch = useDispatch();

  const { data, status, error } = useBatchedLoansDetails(preState);
  const { data: aggregatorData } = useAllAggregatorsTyped();

  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }

  useEffect(() => {
    // prettier-ignore
    MixPanel.track_pageview({ "page": "Batched Details" });
  }, []);

  // repay Selected Loans
  const viewLoans = (e: any, request: any) => {
    e.preventDefault();

    dispatch(
      showModal({
        modalIdentifier: "loan",
        dataId: e.target.dataset["ref"],
        action: "batcheddetails",
        request,
      }),
    );
  };

  const resetTable = () => {
    setCount(null);
    setPreState({ ...state, pageNumber: 1 });
  };
  const handleChange = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const modalHandler = (modalData: any, type: any) => {
    dispatch(
      showModal({
        modalIdentifier: "audittrail",
        dataRecord: modalData,
        action: "customrange",
        type,
      }),
    );
  };
  const filterData = (e: any) => {
    e.preventDefault();
    const diffTime =
      new Date(state.endDate).valueOf() - new Date(state.startDate).valueOf();
    if (diffTime < 0) {
      modalHandler("", "alert");
      return;
    } else {
      setPreState(state);
    }
    resetTable();
  };

  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Batched Loans
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading batched loans..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              batched loans, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data.data && data.data.length === 0) ||
      (data && !data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no requests.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            <table className="table">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>Loan Count</th>
                  <th>Aggregator Name</th>
                  <th>Product Name</th>
                  <th>Amount</th>
                  <th>Approved By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.data.map((request: any, _id: number) => {
                    const {
                      aggregatorName,
                      productName,
                      amount,
                      loanCount,
                      disbursedBy,
                    } = request;
                    return (
                      <tr key={_id}>
                        <td>{loanCount}</td>
                        <td>{aggregatorName}</td>
                        <td>{productName}</td>
                        <td>{amount}</td>
                        <td>{disbursedBy}</td>
                        <td>
                          <button
                            className="btn advancly-nobg-btn btn-sm transition-3d-hover mt-2 mr-2"
                            onClick={(e) => viewLoans(e, request)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  return (
    <div>
      <div className="d-flex flex-row flex-wrap mb-3 mt-3 ">
        <form className="d-flex flex-row flex-wrap" onSubmit={filterData}>
          <div
            style={{ width: "200px", marginBottom: "10px" }}
            className="mr-3"
          >
            <CustomSelectDropdown
              onChange={(e) => handleChange(e)}
              name="aggregatorId"
              value={state.aggregatorId}
              className="round user__form form-control"
            >
              <option value="">search by aggregator</option>
              {aggregatorData &&
                aggregatorData.data?.data.map((aggregator) => {
                  const { biz_name, aggregator_id } = aggregator;
                  return (
                    <option value={Number(aggregator_id)} key={aggregator_id}>
                      {biz_name}
                    </option>
                  );
                })}
            </CustomSelectDropdown>
          </div>
          {/* <div
            style={{ width: "200px", marginBottom: "10px" }}
            className="mr-3"
          >
            <CustomSelectDropdown
              onChange={(e) => handleChange(e)}
              name="productId"
              value={state.productId}
              className="round user__form form-control"
            >
              <option value="">search by product</option>
              {productData &&
                productData.map((aggregator) => {
                  const { product_name, id } = aggregator;
                  return (
                    <option value={Number(id)} key={id}>
                      {product_name}
                    </option>
                  );
                })}
            </CustomSelectDropdown>
          </div> */}
          <div
            style={{ width: "200px", marginBottom: "10px" }}
            className="mr-3"
          >
            <CustomSelectDropdown
              onChange={(e) => handleChange(e)}
              name="disbursed"
              value={state.disbursed}
              className="round user__form form-control"
            >
              <option value="">Disbursed?</option>
              <option value={"true"}>True</option>
              <option value={"false"}>False</option>
            </CustomSelectDropdown>
          </div>

          <div className="form-group mr-3 mt-2">
            <div className="input-group ">
              <label className="mr-2">From </label>
              <input
                type="date"
                name="startDate"
                className="form-control"
                value={state.startDate}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="form-group mt-2 mr-3">
            <div className="input-group">
              <label className="mr-2">To </label>
              <input
                type="date"
                className="form-control"
                name="endDate"
                value={state.endDate}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div style={{ marginBottom: "7px" }}>
            <button
              type="submit"
              className="btn advancly-btn btn-md"
              value="submit"
              disabled={status === "loading" || !data}
            >
              Search
            </button>
          </div>
        </form>
      </div>

      {/*Datatable || Loader || Error Message*/}
      {RenderedComponent}
      <Paginator
        size={preState.size}
        page={preState.pageNumber}
        count={count || 0}
        changeCurrentPage={(pageNumber: number) =>
          setPreState({ ...state, pageNumber })
        }
      />
    </div>
  );
};

export default BatchedDetails;
