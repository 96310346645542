import React, { useEffect, useState } from "react";
import { ReactComponent as UserIcon } from "../../../../../../assets/svg/icons/user-no-fill.svg";
import DirectDebitMandateModalPrompt from "./DirectDebitMandateModalPrompt";
import CustomSelectDropdown from "../../../../../../NewComponents/CustomHTMLElements/CustomSelectDropdown";
import useDirectDebitListAccounts from "../../../../../../custom-hooks/useDirectDebitListAccounts";
import useDirectDebitMandateList from "../../../../../../custom-hooks/useDirectDebitMandateList";
import { formatMoney } from "accounting";
import useDirectDebitQueryStatus from "../../../../../../custom-hooks/useDirectDebitQueryStatus";
import { useQueryCache } from "react-query";
import { Loader } from "../../../../../../UI/Loaders/Loaders";
import { postData } from "../../../../../../newApis/apiMethods";
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";
import { appInsights } from "../../../../../../config/appInsights";
import { errorHandler } from "../../../../../../helpers/errorHandler";
import { IState } from "../../interface";
import MandatePayload from "./MandatePayload";
import { statusProps } from "../LoanSchedule/type";
import Alert from "../../../../../../NewComponents/TypedComponents/Alert/Alert.component";
import { getData } from "../../../../../../newApis/dotNetApiMethods";
import { MixPanel } from "../../../../../../shared/MixPanel/MixPanel";
export interface IMandatePreviewDetails {
  loanId: number;
  reference: string;
  payerBank: string;
  payerAccountNumber: string;
  payerPhone: string;
  payerEmailAddress: string;
  payerAddress: string;
  receiverBank: string;
  receiverAccountNumber: string;
  amount: string;
  startDate: string;
  endDate: string;
  frequency: string;
  frequencyName: string;
  payerbankName: string;
  receiverBankName: string;
  loanRef: string;
  currency: string;
}
const DirectDebit = ({
  loan_ref,
  loan_id,
  first_name,
  last_name,
  properties,
  state,
}: {
  loan_ref: string;
  loan_id: number;
  first_name: string;
  last_name: string;
  state: IState;
  properties: any;
}) => {
  const [mandateQueryStatusRefCode, setmandateQueryStatusRefCode] =
    useState<any>();
  const [currentBankAccount, setCurrentBankAccount] = useState<string | null>(
    null,
  );
  const [success, setSuccess] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const queryCache = useQueryCache();

  useEffect(() => {
    // prettier-ignore
    MixPanel.track_pageview({ "page": "Direct Debit" });
  }, []);

  const customerId = properties?.dataRecord?.borrower_details?.customer_id;
  //  mandate preview  state

  const [mandatePreviewLoading, setMandatePreviewLoading] = useState(false);
  const [mandatePreviewError, setMandatePreviewError] = useState<string | null>(
    null,
  );
  const [mandatePreviewSuccess, setMandatePreviewSuccess] = useState<
    string | null
  >("");
  const [mandatePreviewResponseData, setMandatePreviewResponseData] =
    useState<IMandatePreviewDetails | null>(null);

  const [requestPayLoad, setRequestPayLoad] = useState<any>();
  const [responsePayLoad, setResponsePayLoad] = useState<any>();
  const [resendMandateStatus, setResendMandateStatus] = useState<statusProps>();

  const {
    data: directDebitListAccountsData,
    isLoading: DirectDebitListAccountsLoading,
    error: DirectDebitListAccountsError,
  } = useDirectDebitListAccounts(customerId);

  const {
    data: directDebitMandateTableList,
    isLoading: directDebitMandateTableListLoading,
    error: directDebitMandateTableListError,
  } = useDirectDebitMandateList({
    account_number: currentBankAccount ? currentBankAccount : "",
    loan_ref,
  });

  const {
    data: directDebitQueryStatus,
    isLoading: directDebitQueryLoading,
    error: directDebitQueryError,
    refetch,
  } = useDirectDebitQueryStatus({
    mandate_reference_code: mandateQueryStatusRefCode,
    loan_ref,
  });
  const handleRadioChange = (option: any) => {
    setCurrentBankAccount(option);
  };

  // check the values create mandate repayment preview

  const createMandatePreview = async () => {
    setMandatePreviewLoading(true);
    setMandatePreviewError(null);
    setMandatePreviewSuccess("");
    const reqBody = {
      loanId: loan_id,
      customerBankAccountNumber: currentBankAccount,
      principalAmount: state.amountApproved,
      loanEffectiveDate: state.selectedDate,
    };
    if (
      state.amountApproved &&
      state.selectedDate &&
      loan_id &&
      state.selectedDate
    ) {
      try {
        const response = await postData(
          ajaxEndpoints.DIRECT_DEBIT_EMANDATE_PREVIEW,
          reqBody,
        );

        setMandatePreviewResponseData(response.data);
        setMandatePreviewLoading(false);
        setMandatePreviewSuccess(response?.message);

        setTimeout(() => {
          setMandatePreviewSuccess("");
        }, 3000);

        setTimeout(() => {
          setSuccess("");
        }, 5000);
      } catch (error) {
        appInsights.trackException({
          //@ts-ignore
          exception: error,
          properties: { fileName: "DirectDebit.tsx" },
        });
        setMandatePreviewLoading(false);

        setMandatePreviewError(errorHandler(error));
      }
    }
  };

  useEffect(() => {
    if (mandateQueryStatusRefCode) {
      refetch();

      if (directDebitQueryStatus?.direct_debit_mandate_status) {
        queryCache.invalidateQueries([
          {
            loan_ref: loan_ref,
            account_number: currentBankAccount || "",
          },
          "getDirectDebitMandateList",
        ]);
      }
    }
  }, [
    mandateQueryStatusRefCode,
    directDebitQueryStatus,
    refetch,
    queryCache,
    loan_ref,
    currentBankAccount,
  ]);
  const handleRefQuery = (reference_code: string) => {
    setmandateQueryStatusRefCode(reference_code);
  };

  const handleResendMandateNotification = async (reference_code: string) => {
    setResendMandateStatus({ status: "loading", error: false });

    try {
      const response = await getData(
        `${ajaxEndpoints.RESEND_MANDATE_ACTIVATION}?loan_ref=${loan_ref}&mandate_reference_code=${reference_code}`,
      );

      if (response?.data?.status === true) {
        setResendMandateStatus({
          status: "success",
          error: false,
          message: response?.data?.message,
        });
      } else {
        setResendMandateStatus({
          status: "error",
          error: true,
        });
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "DirectDebit.tsx",
        },
      });
      setResendMandateStatus({
        status: "error",
        error: true,
        message: error?.response?.data.message ?? error.message,
      });
    }
  };

  const handlePayloadData = (
    direct_debit_request_payload: any,
    direct_debit_response_payload: any,
  ) => {
    if (direct_debit_request_payload && direct_debit_response_payload) {
      setRequestPayLoad(direct_debit_request_payload);
      setResponsePayLoad(direct_debit_response_payload);
    }
  };
  const scrollToTop = () => {
    //@ts-ignore
    document.getElementById("topOfPage").scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <div
      id="topOfPage"
      style={{
        padding: "1rem",
        height: "70vh",
        scrollbarColor: "auto",
        overflowY: "scroll",
      }}
    >
      {success && (
        <p className="alert alert-success text-align-center py-2">{success}</p>
      )}
      {error && (
        <p className="alert alert-danger text-align-center py-2">{error}</p>
      )}
      {directDebitMandateTableListError && (
        <p className="alert alert-danger text-align-center py-2">
          {directDebitMandateTableListError as string}
        </p>
      )}
      {directDebitQueryError && (
        <p className="alert alert-danger text-align-center py-2">
          {directDebitQueryError as string}
        </p>
      )}
      {DirectDebitListAccountsError && (
        <p className="alert alert-danger text-align-center py-2">
          {DirectDebitListAccountsError as string}
        </p>
      )}

      <p className="py-2">Please select Direct Debit Account </p>
      <div>
        <UserIcon />
        User: <span>{`${first_name}  ${last_name}`}</span>
      </div>
      {/* <div className="py-3">
        {DirectDebitMandateError ? (
          <p className="text-danger">{DirectDebitMandateError as any}</p>
        ) : (
          ""
        )}
        {DirectDebitMandateLoading ? (
          <Loader />
        ) : (
          directDebitMandateData?.map((mandateDetails: any) => {
            return (
              <>
                <CustomDirectButtonRadioButton
                  mandateDetails={mandateDetails}
                  isChecked={
                    selectedOption ===
                    mandateDetails?.customer_bank_account_number
                  }
                  onChange={() =>
                    handleRadioChange(
                      mandateDetails?.customer_bank_account_number
                    )
                  }
                />
              </>
            );
          })
        )}
      </div> */}

      <div className="d-flex justify-content-between align-items-center">
        <div className="mt-3 mb-3 pb-3 " style={{ width: "80%" }}>
          <CustomSelectDropdown
            label="Select Direct Debit Accounts"
            extraLabel={DirectDebitListAccountsLoading ? "loading..." : ""}
            onChange={(e) => {
              handleRadioChange(e.target.value);
            }}
            name="directDebitAccounts"
            className="round user__form form-control"
          >
            <option value="">All Direct Debit Accounts</option>
            {directDebitListAccountsData &&
              directDebitListAccountsData?.bank_accounts?.map(
                (bankAccountDetails: {
                  account_number: string;
                  account_name: string;
                  bank_name: string;
                  is_default: boolean;
                }) => {
                  const {
                    account_number,
                    account_name,
                    is_default,
                    bank_name,
                  } = bankAccountDetails;
                  return (
                    <option value={account_number} key={account_number}>
                      {`${bank_name}-${account_number}-${account_name} ${
                        is_default ? "(Selected by customer)" : ""
                      } `}
                    </option>
                  );
                },
              )}
          </CustomSelectDropdown>
        </div>
        <div>
          <button
            type="button"
            data-toggle="modal"
            data-target="#directDebitMandateSubmit"
            className="btn zoom-element btn login-btn m-0 mr-auto"
            disabled={!currentBankAccount}
          >
            Create Mandate
          </button>
        </div>
      </div>

      {/* Table  */}
      {directDebitMandateTableListLoading || directDebitQueryLoading ? (
        <div>
          <Loader centered={true} text="Loading table..." />
        </div>
      ) : (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className="table-responsive">
            {directDebitMandateTableList?.length === 0 ? (
              <div className="d-flex justify-content-center align-items-center mx-0">
                <p
                  className=""
                  style={{
                    verticalAlign: "middle",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  No Mandate has been created for this account
                </p>
              </div>
            ) : (
              <table className="table">
                <thead className="bg-lighter-grey">
                  <tr>
                    <th>Bank</th>
                    <th>Account Number</th>
                    <th>Ref Code</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Action status</th>
                  </tr>
                </thead>
                <tbody>
                  {directDebitMandateTableList &&
                    directDebitMandateTableList?.map((request: any) => {
                      const {
                        customer_bank_account_name,
                        customer_bank_account_number,
                        amount,
                        direct_debit_mandate_status,
                        id,
                        reference_code,
                        direct_debit_request_payload,
                        direct_debit_response_payload,
                      } = request;

                      return (
                        <tr key={id}>
                          <td>{customer_bank_account_name}</td>
                          <td>{customer_bank_account_number}</td>
                          <td>{reference_code}</td>
                          <td>{formatMoney(amount, "NGN")}</td>
                          <td>{direct_debit_mandate_status || "N/A"}</td>
                          <td>
                            {/* <button
                              className="btn zoom-element btn login-btn m-0 mr-auto"
                              onClick={() => {
                                handleRefQuery(reference_code);
                              }}
                            >
                              Get Status{" "}
                            </button> */}

                            <div className="dropdown">
                              <button
                                className="btn zoom-element btn-slim dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              ></button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <span
                                  className="Loan cursor-pointer dropdown-item"
                                  role="button"
                                  aria-roledescription="button"
                                  onClick={() => {
                                    handleRefQuery(reference_code);
                                  }}
                                >
                                  Get Status{" "}
                                </span>
                                {direct_debit_mandate_status === "Pending" && (
                                  <span
                                    className="Loan cursor-pointer dropdown-item"
                                    role="button"
                                    aria-roledescription="button"
                                    onClick={() => {
                                      handleResendMandateNotification(
                                        reference_code,
                                      );
                                    }}
                                  >
                                    Resend Mandate
                                  </span>
                                )}
                                <span
                                  data-toggle="modal"
                                  data-target="#directDebitMandatePayload"
                                  className="Loan cursor-pointer dropdown-item"
                                  role="button"
                                  aria-roledescription="button"
                                  onClick={() => {
                                    handlePayloadData(
                                      direct_debit_request_payload,
                                      direct_debit_response_payload,
                                    );
                                  }}
                                >
                                  View Mandate PayLoad
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      )}
      <DirectDebitMandateModalPrompt
        setError={setError}
        setSuccess={setSuccess}
        mandatePreviewLoading={mandatePreviewLoading}
        mandatePreviewError={mandatePreviewError}
        mandatePreviewSuccess={mandatePreviewSuccess}
        loan_id={loan_id}
        loan_ref={loan_ref}
        setCurrentBankAccount={setCurrentBankAccount}
        currentBankAccount={currentBankAccount}
        scrollToTop={scrollToTop}
        createMandatePreview={createMandatePreview}
        mandatePreviewResponseData={mandatePreviewResponseData}
      />

      <MandatePayload
        requestPayLoad={requestPayLoad}
        responsePayLoad={responsePayLoad}
      />

      {resendMandateStatus?.error ? (
        <Alert message={`${resendMandateStatus.message}`} />
      ) : (
        resendMandateStatus?.status === "success" && (
          <Alert type="success" message={`${resendMandateStatus.message}`} />
        )
      )}
    </div>
  );
};

export default DirectDebit;
