import { useState, useEffect } from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
// Router
import { useHistory } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

// UI Components
import { Loader } from "../../../UI/Loaders/Loaders";
import Paginator from "../../Pagination/Pagination.component";
import { formatMoney } from "../../../helpers/formatter";
import useLoansPendingApproval from "../../../custom-hooks/useLoansPendingApproval";
import { attachClassNameToTag } from "../../../helpers/attachClassNameToTag";
import { showModal } from "../../../redux/actions/modal";
import { RootState } from "../../../redux/store";
import { MixPanel } from "../../../shared/MixPanel/MixPanel";

const PendingApprovals = (props: any) => {
  const [count, setCount] = useState(null);
  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 20,
    startDate: "",
    endDate: "",
    borrowerName: "",
    loanId: "",
    bvn: "",
    rcNumber: "",
    aggregatorId: "",
    loanRef: "",
  });
  const [refresh, setRefresh] = useState(false);

  const { isAuthenticated, loggedInUser } = useSelector(
    (state: RootState) => state?.authReducer,
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const resetTable = () => {
    setCount(null);
  };
  const resetSearch = (e: any) => {
    e.preventDefault();
    setPreState({
      pageNumber: 1,
      size: 20,
      startDate: "",
      endDate: "",
      borrowerName: "",
      loanId: "",
      bvn: "",
      rcNumber: "",
      aggregatorId: "",
      loanRef: "",
    });
    setRefresh(true);
  };

  //  Life Cycle Hooks
  useEffect(() => {
    // prettier-ignore
    MixPanel.track_pageview({ "page": "Pending Approvals" });
  }, []);

  useEffect(() => {
    //  If user is not authenticates, redirect to login
    if (!isAuthenticated) {
      history.push("/login");
    }
  }, [history, isAuthenticated, loggedInUser]);

  //JSX Template
  let RenderedComponent = null;

  const { data, status, error } = useLoansPendingApproval(preState);

  const modalHandler = (loan: any) => {
    dispatch(
      showModal({
        modalIdentifier: "loan",
        dataRecord: loan,
        action: "approval_trail",
      }),
    );
  };

  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }

  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Uploaded Loans
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Loans Pending Approval..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              loans pending approval, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (data && data.data && data.data.length === 0) ||
      (data && !data.data)
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no pending
              loans.
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <MDBTable responsive minimal="true" bordered hover id="table-to-xls">
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Aggregator Name</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Borrower Type</th>
                <th>Loan Status</th>
                <th>Product Name</th>
                <th>Loan Amount</th>
                <th>Requested Amount</th>
                <th>Loan Ref</th>
                <th>Created Date</th>
                <th>Trust Score</th>
                <th>Consolidated Score Decision</th>
                <th>Superfluid Score</th>
                <th>Superfluid Status</th>
                <th>Manage</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {data?.data.map((data: any, _id: number) => {
                const {
                  aggregator_details,
                  borrower_details,
                  loan_ref,
                  product_name,
                  loan_amount,
                  pub_date,
                  sf_score,
                  sf_status,
                  trust_score,
                  disbursement_decision,
                  currency,
                  status,
                  loan_amount_requested,
                  loan_id,
                } = data;

                return (
                  <tr key={uuidv4()}>
                    <td>
                      {(preState.pageNumber - 1) * preState.size + (_id + 1)}
                    </td>
                    <td>{aggregator_details?.biz_name}</td>
                    <td>{borrower_details?.first_name}</td>
                    <td>{borrower_details?.last_name}</td>
                    <td>{borrower_details?.borrower_type_value}</td>
                    <td>
                      <span className={attachClassNameToTag(status)}>
                        {attachClassNameToTag(status)}
                      </span>
                    </td>
                    <td>{product_name}</td>
                    <td>{formatMoney(Number(loan_amount), currency)}</td>
                    <td>
                      {formatMoney(Number(loan_amount_requested), currency)}
                    </td>
                    <td>{loan_ref}</td>
                    <td>{moment(pub_date).format("DD/MM/YYYY")}</td>
                    <td>{trust_score}</td>
                    <td>{disbursement_decision}</td>
                    <td>{sf_score}</td>
                    <td>{sf_status}</td>
                    <td>
                      <span className="d-flex ">
                        <span
                          key="0"
                          id="0"
                          data-table="Loan"
                          data-action="View"
                          className="Loan view btn-manage zoom-element btn-slim"
                          onClick={() => {
                            modalHandler(data);
                          }}
                        >
                          View
                        </span>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </MDBTableBody>
          </MDBTable>
        </div>
      );
    }
  }

  return (
    <div className="datatable-wrapper">
      <div className="table-view speed-1x fadeInRight">
        {/*Audit Trail Header*/}
        <div className="block-header">
          <div className="row">
            <div className="col-12 dashboard-title flex__row">
              <h3>Loans Pending Approval</h3>
            </div>
          </div>
        </div>

        <div className="d-flex flex-row flex-wrap justify-content-between align-items-end mt-4">
          <div className="m-r-1">
            <label htmlFor="table-fetch" className="form-group">
              Number of results:{" "}
              <select
                onChange={(e) => {
                  resetTable();
                  setPreState({
                    ...preState,
                    size: Number(e.target.value),
                    pageNumber: 1,
                  });
                }}
                id="table-fetch"
                className="table-fetch-select"
                value={preState.size}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </label>
          </div>

          <div className="flex-row mb-1">
            <button
              id="create"
              data-id="create-role"
              data-action="create"
              className="m-r-1 create btn advancly-btn"
              onClick={(e) => {
                dispatch(
                  showModal({
                    modalIdentifier: "loan",
                    action: "pendingapproval",
                    setPreState,
                    preState,
                    refresh,
                  }),
                );
              }}
            >
              <i className="fas fa-search" />
              &nbsp; Advance Search
            </button>
            <button
              id="create"
              data-id="create-role"
              data-action="create"
              className=" btn advancly-white-btn"
              onClick={(e) => {
                resetSearch(e);
              }}
            >
              <i className="fas fa-refresh" />
            </button>
          </div>
        </div>

        {/*Datatable || Loader || Error Message*/}
        {RenderedComponent}

        <Paginator
          size={preState.size}
          page={preState.pageNumber}
          count={count || 0}
          changeCurrentPage={(pageNumber: number) =>
            setPreState({ ...preState, pageNumber })
          }
        />
      </div>
    </div>
  );
};

export default PendingApprovals;
