import React, { useEffect } from "react";
import ModalDetailsRow from "../../../components/ModalDetailsRow/ModalDetailsRow.component";
import { MixPanel } from "../../../shared/MixPanel/MixPanel";

const AuditTrailModal = ({
  properties,
  properties: { dataRecord, type },
  onCloseModal,
}) => {
  const printHandler = () => {};
  const { action_date, audit_type, username, client_ip_address } = dataRecord;

  useEffect(() => {
    // prettier-ignore
    MixPanel.track_pageview({ "page": "Audit Trail" });
  }, []);
  return (
    <div className="Modal__dialog animated slideInDown" role="document">
      <div
        className="AuditTrailModal Modal__content"
        style={{ overflow: "hidden" }}
      >
        {(() => {
          switch (type) {
            case "alert":
              return (
                <div className="Modal__body text-center">
                  <h3 className="Modal__title p-b-2 text-center">
                    Invalid date range
                  </h3>
                  <button
                    type="button"
                    className="btn zoom-element btn login-btn m-t-1"
                    onClick={onCloseModal}
                    data-dismiss="modal"
                  >
                    <i className="fas fa-times-circle"></i>
                    &nbsp; Go Back
                  </button>
                </div>
              );
            default:
              return (
                <div className="Modal__body">
                  <h3 className="Modal__title p-b-2 text-center">
                    Audit Trail Information
                  </h3>
                  {/* AuditTrail Modal */}
                  <form>
                    <ModalDetailsRow
                      label="Username"
                      value={username}
                      icon="fas fa-user-circle"
                    />
                    <ModalDetailsRow
                      label="Client IP Address"
                      value={client_ip_address}
                      icon="far fa-calendar-alt"
                    />
                    <ModalDetailsRow
                      label="Audit Type"
                      value={audit_type}
                      icon="fas fa-user-circle"
                    />
                    <ModalDetailsRow
                      label="Date/Time"
                      value={`${action_date.substring(
                        0,
                        10,
                      )} / ${action_date.substring(11, 19)}`}
                      icon="fas fa-user-circle"
                    />

                    <div className="Form__action-buttons m-t-1">
                      <button
                        type="button"
                        className="btn zoom-element btn login-btn m-0 mr-auto"
                        onClick={printHandler}
                      >
                        <i className="fas fa-print"></i>
                        &nbsp; Print
                      </button>
                      <button
                        type="button"
                        className="btn zoom-element btn login-btn m-0 ml-auto"
                        onClick={onCloseModal}
                        data-dismiss="modal"
                      >
                        <i className="fas fa-times-circle"></i>
                        &nbsp; Go Back
                      </button>
                    </div>
                  </form>
                </div>
              );
          }
        })()}
      </div>
    </div>
  );
};

export default AuditTrailModal;
