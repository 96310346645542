import Styles from "./globalWaitListDetails.module.scss";
import { WaitListDetail } from "../../../components/AggregatorOnboarding/GlobalWaitlist/type";
import { useEffect } from "react";
import { MixPanel } from "../../../shared/MixPanel/MixPanel";

interface GlobalWaitlistProps {
  dataRecord: WaitListDetail;
  onCloseModal: () => void;
}

const GlobalWaitlistDetails = ({
  dataRecord,
  onCloseModal,
}: GlobalWaitlistProps) => {
  useEffect(() => {
    // prettier-ignore
    MixPanel.track_pageview({ "page": "Global Waitlist Details" });
  }, []);

  return (
    <div
      className={`Modal__dialog animated slideInDown ${Styles.Container}`}
      role="document"
    >
      <div className="Modal__content" style={{ overflow: "hidden" }}>
        <div className={`Modal__body ${Styles.Body}`}>
          <div>
            <div className={`${Styles.FormContainer} mt-30`}>
              <div className={Styles.Form}>
                <div className={Styles.FormHeader}>
                  <h3>Waitlist Detail</h3>
                </div>

                <div className={Styles.BioData}>
                  <span>Email: {dataRecord?.personal_email}</span>
                  <span>Location: {dataRecord?.country}</span>
                </div>

                <p className={Styles.Description}>{dataRecord?.description}</p>

                <div className={`m-t-1 ${Styles.FormButtonContainer}`}>
                  <button
                    type="button"
                    className="btn advancly-btn btn-md m-0"
                    onClick={onCloseModal}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalWaitlistDetails;
