import "./FailedDisbursement.css";
// Select Action

import Retry from "./Retry/Retry";
import Details from "./Details/Details";
import { useEffect } from "react";
import { MixPanel } from "../../../shared/MixPanel/MixPanel";

const FailedDisbursementModal = (props: any) => {
  const { properties } = props;
  const { action } = properties;

  useEffect(() => {
    // prettier-ignore
    MixPanel.track_pageview({ "page": "Failed Disbursement" });
  }, []);

  return (
    <div className="Modal__dialog animated slideInDown" role="document">
      {(() => {
        switch (action) {
          case "details":
            return (
              <Details
                properties={properties}
                onCloseModal={props.onCloseModal}
              />
            );

          case "retry":
            return (
              <Retry
                properties={properties}
                onCloseModal={props.onCloseModal}
              />
            );

          default:
            return null;
        }
      })()}
    </div>
  );
};

export default FailedDisbursementModal;
