import React, { Component } from "react";

// Redux
import { connect } from "react-redux";
import { setToast } from "../../../../redux/actions/toast";
import { removeDisbursedLoans } from "../../../../redux/actions/loans";

// Ajax Methods
import ajaxMethods from "../../../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../../../api/ajax-endpoints";

// UI Components
import { ErrorLoader } from "../../../../UI/Loaders/Loaders";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { appInsights } from "../../../../config/appInsights";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";
class Approve extends Component {
  state = {
    comment: "",
    auth: "",
    failedApprovedBatchLoans: false,
    successfulApprovedBatchLoans: false,
    failedRejectedBatchLoans: false,
    successfulRejectedBatchLoans: false,
  };

  componentDidMount() {
    // prettier-ignore
    MixPanel.track_pageview({ "page": "Approve" });
  }

  // Disburse single Loan

  disburseLoan = (e) => {
    e.preventDefault();

    const params = {
      loan_refs: [this.props.properties.dataRecord.loan_ref],
      comment: this.state.comment,
      otp: this.state.auth,
    };
    document.querySelector("#disbursement-confirm").classList.add("hidden");
    document.querySelector("#disbursement-loader").classList.remove("hidden");

    ajaxMethods
      .post(ajaxEndpoints.APPROVE_MULTIPLE_PENDING_DISBURSEMENT, params)
      .then((response) => {
        //   let message = "";
        if (response.status === 200) {
          document
            .querySelector("#disbursement-loader")
            .classList.add("hidden");
          document
            .querySelector("#disbursement-success")
            .classList.remove("hidden");
          if (response.data.data.message) {
            return setTimeout(() => {
              this.props.setToast("success", response.data.data.message);
              this.props.properties.result();
              this.props.onCloseModal();
            }, 3000);
          } else if (response.data.data) {
            return setTimeout(() => {
              this.props.setToast(
                "success",
                response?.data?.data?.success[0]?.data?.data?.message ??
                  "Success",
              );
              this.props.properties.result();
              this.props.onCloseModal();
            }, 3000);
          }
        } else if (response.status === 400) {
          // response?.data?.data?.failure
          // response?.data?.data?.success
          document
            .querySelector("#disbursement-loader")
            .classList.add("hidden");
          document
            .querySelector("#disbursement-error")
            .classList.remove("hidden");

          setTimeout(() => {
            this.props.setToast(
              "warning",
              response?.data?.data?.failure[0]?.message,
            );
            return this.props.onCloseModal();
          }, 3000);
        } else {
          document
            .querySelector("#disbursement-loader")
            .classList.add("hidden");
          document
            .querySelector("#disbursement-error")
            .classList.remove("hidden");

          // setTimeout(() => {
          //   this.props.setToast("warning", "Could not disburse selected loans");
          //   return this.props.onCloseModal();
          // }, 3000);
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ApproveMakerChecker.js",
          },
        });
        document.querySelector("#disbursement-loader").classList.add("hidden");
        document
          .querySelector("#disbursement-error")
          .classList.remove("hidden");
        setTimeout(() => {
          this.props.setToast(
            "warning",
            "Unable to connect to Advancly services",
          );
          this.props.properties.result();
          return this.props.onCloseModal();
        }, 3000);
      });
  };
  // Approve multiple loans
  disburseMultipleLoan = async (e) => {
    const { loans } = this.props.properties;
    let Paramms = {
      loan_refs: loans,
      comment: this.state.comment,
      otp: this.state.auth,
    };
    document.querySelector("#disbursement-confirm").classList.add("hidden");
    document.querySelector("#disbursement-loader").classList.remove("hidden");
    ajaxMethods
      .post(ajaxEndpoints.APPROVE_MULTIPLE_PENDING_DISBURSEMENT, Paramms)
      .then((response) => {
        if (response.status === 200) {
          // Refetch Loans pending disbursement
          document
            .querySelector("#disbursement-loader")
            .classList.add("hidden");
          document
            .querySelector("#disbursement-success")
            .classList.remove("hidden");
          if (response?.data?.data?.success.length > 0) {
            this.setState({
              successfulApprovedBatchLoans: response.data.data.success,
            });

            this.props.properties.result();
          }
          if (response?.data?.data?.failure.length > 0) {
            this.setState({
              failedApprovedBatchLoans: response.data.data.failure,
            });

            this.props.properties.result();
          }
        } else if (response.status_code === 400 || response.status === 400) {
          document
            .querySelector("#disbursement-loader")
            .classList.add("hidden");
          document
            .querySelector("#disbursement-success")
            .classList.remove("hidden");
          if (response?.data?.data?.success.length > 0) {
            this.setState({
              successfulApprovedBatchLoans: response.data.data.success,
            });

            this.props.properties.result();
          }
          if (response?.data?.data?.failure.length > 0) {
            this.setState({
              failedApprovedBatchLoans: response.data.data.failure,
            });

            this.props.properties.result();
          }
        } else {
          document
            .querySelector("#disbursement-loader")
            .classList.add("hidden");
          document
            .querySelector("#disbursement-error")
            .classList.remove("hidden");
          setTimeout(() => {
            this.props.setToast(
              "warning",
              "Unable to connect to Advancly services",
            );
            return this.props.onCloseModal();
          }, 3000);
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ApproveMakerChecker.js",
          },
        });
        document.querySelector("#disbursement-loader").classList.add("hidden");
        document
          .querySelector("#disbursement-error")
          .classList.remove("hidden");
        setTimeout(() => {
          this.props.setToast(
            "warning",
            "Unable to connect to Advancly services.",
          );
          this.props.properties.result();
          return this.props.onCloseModal();
        }, 3000);
      });
  };
  // Reject multiple loans
  rejectMultipleLoan = async (e) => {
    const { loans } = this.props.properties;
    let params = {
      loan_refs: loans,
      comment: this.state.comment,
      otp: this.state.auth,
    };
    document.querySelector("#disbursement-confirm").classList.add("hidden");
    document.querySelector("#disbursement-loader").classList.remove("hidden");
    ajaxMethods
      .post(ajaxEndpoints.REJECT_MULTIPLE_PENDING_DISBURSEMENT, params)
      .then((response) => {
        if (response.status === 200) {
          document
            .querySelector("#disbursement-loader")
            .classList.add("hidden");
          document
            .querySelector("#disbursement-success")
            .classList.remove("hidden");
          if (response?.data?.data?.success.length > 0) {
            this.setState({
              successfulRejectedBatchLoans: response?.data?.data?.success,
            });
            this.props.properties.result();
            // this.props.onCloseModal();
          }
          if (response?.data?.data?.failure.length > 0) {
            this.setState({
              failedRejectedBatchLoans: response.data.data.failure,
            });
            this.props.properties.result();
          }
        } else if (response.status === 400) {
          document
            .querySelector("#disbursement-loader")
            .classList.add("hidden");
          document
            .querySelector("#disbursement-success")
            .classList.remove("hidden");
          if (response?.data?.data?.success.length > 0) {
            this.setState({
              successfulRejectedBatchLoans: response?.data?.data?.success,
            });
            this.props.properties.result();
          }
          if (response?.data?.data?.failure.length > 0) {
            this.setState({
              failedRejectedBatchLoans: response?.data?.data?.failure,
            });
          } else {
            setTimeout(() => {
              this.props.setToast("warning", response.data.message);
              return this.props.onCloseModal();
            }, 3000);
          }
          this.props.properties.result();
        } else {
          document
            .querySelector("#disbursement-loader")
            .classList.add("hidden");
          document
            .querySelector("#disbursement-error")
            .classList.remove("hidden");
          setTimeout(() => {
            this.props.setToast("warning", response.data.message);
            return this.props.onCloseModal();
          }, 3000);
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "ApproveMakerChecker.js",
          },
        });
        document.querySelector("#disbursement-loader").classList.add("hidden");
        document
          .querySelector("#disbursement-error")
          .classList.remove("hidden");
        setTimeout(() => {
          this.props.setToast(
            "warning",
            "Unable to connect to Advancly services",
          );
          this.props.properties.result();
          return this.props.onCloseModal();
        }, 3000);
      });
  };

  // get comment
  onCommentChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  render() {
    const { subModal } = this.props.properties;
    let renderSuccessfulApprovedData,
      renderApprovedFailedData,
      renderSuccessfulRejectedData,
      renderRejectedFailedData;
    const {
      comment,
      failedApprovedBatchLoans,
      successfulApprovedBatchLoans,
      failedRejectedBatchLoans,
      successfulRejectedBatchLoans,
      auth,
    } = this.state;
    if (successfulApprovedBatchLoans || failedApprovedBatchLoans) {
      if (successfulApprovedBatchLoans) {
        renderSuccessfulApprovedData = (
          <div>
            <p className="text-success">
              See successful approved loans below:{" "}
            </p>
            <MDBTable responsive minimal="true" bordered hover>
              <MDBTableHead>
                <tr>
                  <th style={{ width: 50 }}>Loan ref</th>
                  <th>Message</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {successfulApprovedBatchLoans.map((successfulBatchLoan) => {
                  return (
                    <tr>
                      <td style={{ width: 50 }}>
                        {successfulBatchLoan?.message || ""}
                      </td>
                      <td>{successfulBatchLoan?.data.message || ""}</td>
                    </tr>
                  );
                })}
              </MDBTableBody>
            </MDBTable>
          </div>
        );
      }
      if (failedApprovedBatchLoans) {
        renderApprovedFailedData = (
          <div>
            <p className="text-danger">
              See failed approved loan reasons below:{" "}
            </p>
            <MDBTable responsive minimal="true" bordered hover>
              <MDBTableHead>
                <tr>
                  <th style={{ width: 50 }}>Loan ref</th>
                  <th>Message</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {failedApprovedBatchLoans.map((failedBatchLoan) => {
                  return (
                    <tr>
                      <td style={{ width: 50 }}>
                        {failedBatchLoan?.data || ""}
                      </td>
                      <td>{failedBatchLoan?.message || ""}</td>
                    </tr>
                  );
                })}
              </MDBTableBody>
            </MDBTable>
          </div>
        );
      }
    } else if (successfulRejectedBatchLoans || failedRejectedBatchLoans) {
      if (successfulRejectedBatchLoans) {
        renderSuccessfulRejectedData = (
          <div className="m-b-2 m-t-1">
            <p className="text-success">
              See successful rejected loans below:{" "}
            </p>
            <MDBTable responsive minimal="true" bordered hover>
              <MDBTableHead>
                <tr>
                  <th style={{ width: 50 }}>Loan ref</th>
                  <th>Message</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {successfulRejectedBatchLoans.map(
                  (successfulRejectedBatchLoan) => {
                    return (
                      <tr>
                        <td style={{ width: 50 }}>
                          {successfulRejectedBatchLoan?.message || ""}
                        </td>
                        <td>Rejected Successfully</td>
                      </tr>
                    );
                  },
                )}
              </MDBTableBody>
            </MDBTable>
          </div>
        );
      }
      if (failedRejectedBatchLoans) {
        renderRejectedFailedData = (
          <div className="m-b-2 m-t-1">
            <p className="text-danger">
              See failed rejected loans reasons below:{" "}
            </p>
            <MDBTable responsive minimal="true" bordered hover>
              <MDBTableHead>
                <tr>
                  <th style={{ width: 50 }}>Loan ref</th>
                  <th>Message</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {failedRejectedBatchLoans.map((failedRejectedBatchLoan) => {
                  return (
                    <tr>
                      <td style={{ width: 50 }}>
                        {failedRejectedBatchLoan?.data || ""}
                      </td>
                      <td>{failedRejectedBatchLoan?.message || ""}</td>
                    </tr>
                  );
                })}
              </MDBTableBody>
            </MDBTable>
          </div>
        );
      }
    }

    return (
      <div
        className="Modal__dialog lg-modal animated slideInDown"
        role="document"
      >
        <div className="LoanModal Modal__content">
          <div className="Modal__body">
            {(() => {
              switch (subModal) {
                case "single":
                  const { borrower_name, loan_amount } =
                    this.props.properties.dataRecord;
                  return (
                    <React.Fragment>
                      <h3 className="Modal__title p-b-1">Approval Summary</h3>
                      {/* 2. Confirm Update Role Modal*/}
                      <div id="disbursement-confirm">
                        <div className="m-b-30">
                          <h5>
                            Are you sure you want to approve the following loan?{" "}
                          </h5>
                          <p>Borrower Name: {`${borrower_name}`}</p>
                          <p>Loan Amount: {loan_amount}</p>
                          <form>
                            <label style={{ width: "100%" }}>
                              Comment
                              <input
                                className="form-control"
                                name="comment"
                                value={comment}
                                onChange={this.onCommentChange}
                                placeholder="Reason for approving this loan"
                              />
                            </label>
                            <label style={{ width: "50%" }}>
                              Generated 2FA code
                              <input
                                className="form-control"
                                name="auth"
                                value={auth}
                                onChange={this.onCommentChange}
                              />
                            </label>
                          </form>
                          <div className="Form__action-buttons m-t-2">
                            <button
                              type="submit"
                              onClick={this.disburseLoan}
                              disabled={!comment && !auth}
                              className="btn zoom-element btn login-btn m-0 mr-auto"
                            >
                              <i className="fa fa-check-circle" /> Proceed
                            </button>
                            <button
                              type="button"
                              onClick={this.props.onCloseModal}
                              className="btn zoom-element btn login-btn m-0 ml-auto"
                            >
                              <i className="fa fa-times-circle" /> Cancel
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* 2. Disbursement Loader*/}
                      <div
                        id="disbursement-loader"
                        className="hidden loading-overlay speed-1x fadeInRight"
                      >
                        <div className=" form-loader form-loader-login verify">
                          <div className="centerLoader">
                            <div className="loading" id="adv-loader-alt" />
                            <div className="center-txt webapp-ripple-text">
                              <i className="notifier fas fa-user-cog" />
                              Processing Approval ...
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* 3. Disbursement Success Message*/}
                      <div
                        id="disbursement-success"
                        className="hidden loading-overlay speed-1x fadeInRight"
                      >
                        <div className="form-loader form-loader-login verify">
                          <div className="centerLoader">
                            <div className="loading success-loader">
                              <i className="webapp-ripple-notification success-text fa fa-check-circle" />
                            </div>
                            <div className="center-txt webapp-ripple-text">
                              <i className="notifier far fa-check-circle" />
                              Approval Successful
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* 4. Disbursement Error */}
                      <div
                        id="disbursement-error"
                        className="hidden loading-overlay speed-1x fadeInRight"
                      >
                        <ErrorLoader
                          icon="fas fa-wifi"
                          text="Network Issue"
                          subText="Unable to connect to Advancly services."
                        />
                      </div>
                    </React.Fragment>
                  );
                case "multiple":
                  const { loans, totalAmount } = this.props.properties;

                  return (
                    <React.Fragment>
                      <h3 className="Modal__title p-b-1">Bulk Loans Summary</h3>

                      {/* 1. Confirm Disbursement*/}
                      <div id="disbursement-confirm">
                        <div className="m-b-6 m-r-6">
                          <p>
                            You have selected {loans.length} loans for approval
                            with reference{" "}
                            {Object.keys(loans)
                              .map(function (loan) {
                                return loans[loan];
                              })
                              .join(", ")}{" "}
                            .
                          </p>
                          <p>Total Amount is {totalAmount} .</p>
                          <p>
                            Are you sure you want to disburse the following
                            loans?
                          </p>
                          <form>
                            <label style={{ width: "100%" }}>
                              Comment
                              <input
                                className="form-control"
                                name="comment"
                                value={comment}
                                onChange={this.onCommentChange}
                                placeholder="Reason for approving this loan"
                              />
                            </label>
                            <label style={{ width: "50%" }}>
                              Generated 2FA code
                              <input
                                className="form-control"
                                name="auth"
                                value={auth}
                                onChange={this.onCommentChange}
                              />
                            </label>
                          </form>
                          <div className="Form__action-buttons m-t-2">
                            <button
                              type="button"
                              disabled={!comment}
                              onClick={this.disburseMultipleLoan}
                              className="btn zoom-element btn login-btn m-0"
                            >
                              <i className="fa fa-check-circle" /> Approve
                            </button>
                            <button
                              type="button"
                              disabled={!comment}
                              onClick={this.rejectMultipleLoan}
                              className="btn-danger zoom-element btn login-btn "
                            >
                              <i className="fa fa-ban" /> Reject
                            </button>
                            <button
                              type="button"
                              onClick={this.props.onCloseModal}
                              className="btn zoom-element btn login-btn m-0"
                            >
                              <i className="fa fa-times-circle" /> Cancel
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* 2. Disbursement Loader*/}
                      <div
                        id="disbursement-loader"
                        className="hidden loading-overlay speed-1x fadeInRight"
                      >
                        <div className=" form-loader form-loader-login verify">
                          <div className="centerLoader">
                            <div className="loading" id="adv-loader-alt" />
                            <div className="center-txt webapp-ripple-text">
                              <i className="notifier fas fa-user-cog" />
                              Processing......
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* 3. Disbursement Success Message*/}
                      <div
                        id="disbursement-success"
                        className="hidden speed-1x fadeInRight"
                      >
                        {(successfulApprovedBatchLoans ||
                          failedApprovedBatchLoans) && (
                          <div className="m-b-3">
                            <div>
                              <div className="row m-t-2 grid__padding">
                                {successfulApprovedBatchLoans &&
                                successfulApprovedBatchLoans.length > 0 ? (
                                  <div> {renderSuccessfulApprovedData} </div>
                                ) : (
                                  "No Successful Approval."
                                )}
                              </div>
                              <div className="row m-t-2 grid__padding">
                                {failedApprovedBatchLoans &&
                                failedApprovedBatchLoans.length > 0 ? (
                                  <div>{renderApprovedFailedData}</div>
                                ) : (
                                  "No Failed Approval."
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {(successfulRejectedBatchLoans ||
                          failedRejectedBatchLoans) && (
                          <div className="m-b-3">
                            <div>
                              <div className="row m-t-2 grid__padding">
                                {successfulRejectedBatchLoans &&
                                successfulRejectedBatchLoans.length > 0 ? (
                                  <div>{renderSuccessfulRejectedData}</div>
                                ) : (
                                  "No Successful Rejection."
                                )}
                              </div>
                              <div className="row m-t-2 grid__padding">
                                {failedRejectedBatchLoans &&
                                failedRejectedBatchLoans.length > 0 ? (
                                  <div>{renderRejectedFailedData}</div>
                                ) : (
                                  "No Failed Rejection."
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="Form__action-buttons m-t-2"></div>
                        <button
                          type="button"
                          onClick={this.props.onCloseModal}
                          className="btn zoom-element btn login-btn m-0"
                        >
                          <i className="fa fa-times-circle" /> Close
                        </button>
                      </div>

                      {/* 4. Disbursement Error */}
                      <div
                        id="disbursement-error"
                        className="hidden loading-overlay speed-1x fadeInRight"
                      >
                        <ErrorLoader
                          icon="fas fa-wifi"
                          text="Network Issue"
                          subText="Unable to connect to Advancly services."
                        />
                      </div>
                    </React.Fragment>
                  );

                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    disbursements: { records },
  } = state.loansReducer;

  return {
    records,
  };
};

export default connect(mapStateToProps, {
  setToast,
  removeDisbursedLoans,
})(Approve);
