import { MDBDataTable } from "mdbreact";

import useAllRepayment from "../../../custom-hooks/useAllRepayment";
import Loader from "../../../NewComponents/Loader/Loader.component";
import { useEffect } from "react";
import { MixPanel } from "../../../shared/MixPanel/MixPanel";

interface TRepaymentProp {
  aggregator_name: string;
  beneficiary_account_num: string;
  beneficiary_bank_name: string;
  corebanking_status: string;
  currency: string;
  debit_account_name: string;
  debit_account_num: string;
  debit_bank_name: string;
  loan_ref: string;
  repayment_ref: string;
  repayment_status: string;
}

interface IRepayments {
  repayments: TRepaymentProp[];
}

const TransactionHistoryModal = ({
  properties,
  properties: { dataRecord },
  onCloseModal,
}: any) => {
  useEffect(() => {
    // prettier-ignore
    MixPanel.track_pageview({ "page": "Transaction History" });
  }, []);

  const { data: repayments, isLoading } = useAllRepayment({
    page: 1,
    size: 100,
    startDate: "",
    endDate: "",
    loanRef: dataRecord,
  });

  return (
    <div
      className="Modal__dialog lg-modal animated slideInDown"
      role="document"
    >
      <div
        className="AuditTrailModal Modal__content"
        style={{ overflow: "hidden" }}
      >
        <div className="Modal__body">
          {repayments && !isLoading && (
            <Repayments repayments={repayments?.data} />
          )}
          {isLoading && <Loader />}
        </div>
      </div>
    </div>
  );
};

const Repayments = ({ repayments }: IRepayments) => {
  const tableData = {
    columns: [
      {
        field: "loan_ref",
        label: "Loan Reference",
        sort: "",
      },
      {
        field: "currency",
        label: "Currency",
        sort: "",
      },
      {
        field: "repayment_status",
        label: "Repayment Status",
        sort: "",
      },
      {
        field: "aggregator_name",
        label: "Aggregator Name",
        sort: "",
      },
      {
        field: "beneficiary_account_num",
        label: "Beneficiary Account",
        sort: "",
      },
      {
        field: "beneficiary_bank_name",
        label: "Beneficiary Bank Name",
        sort: "",
      },
      {
        field: "debit_account_name",
        label: "Debit Account Name",
        sort: "",
      },
      {
        field: "debit_account_num",
        label: "Debit Account Number",
        sort: "",
      },
      {
        field: "debit_bank_name",
        label: "Debit Account Name",
        sort: "",
      },
    ],
    rows: repayments,
  };

  let renderedData = null;
  if (repayments.length > 0) {
    renderedData = (
      <MDBDataTable
        responsive
        minimal="true"
        striped
        bordered
        hover
        data={tableData}
      />
    );
    return renderedData;
  } else {
    renderedData = (
      <p className="text-center">No Transaction History Available</p>
    );
  }

  return <div className="m-b-2">{renderedData}</div>;
};

export default TransactionHistoryModal;
