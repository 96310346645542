import mixpanel from "mixpanel-browser";
import { MIXPANEL_PROJECT_TOKEN } from "../../appConstants";

interface PeopleProps {
  $name: string;
  $email: string;
  user_id: number;
}

mixpanel.init(MIXPANEL_PROJECT_TOKEN, {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

const env_check = process.env.NODE_ENV === "production";

let actions = {
  identify: (id: string | undefined) => {
    if (env_check) mixpanel.identify(id);
  },
  people: {
    set: (props: PeopleProps) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
  track_pageview: (props: any) => {
    if (env_check) mixpanel.track_pageview(props);
  },
};

export let MixPanel = actions;
