import * as actionTypes from "../actions/actionTypes";

let initialState = {
  showModal: false,
  modalProperties: {},
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SHOW_MODAL:
      return {
        ...state,
        showModal: true,
        modalProperties: { ...action.payload },
      };

    case actionTypes.HIDE_MODAL:
      return {
        ...state,
        showModal: false,
        modalProperties: {},
      };

    default:
      return state;
  }
};

export default reducer;
