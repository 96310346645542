import { useEffect, useState, Fragment } from "react";

// Router
import { withRouter } from "react-router-dom";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { showModal } from "../../../redux/actions/modal";
import { setToast } from "../../../redux/actions/toast";

import { MDBTableHead, MDBTable, MDBTableBody } from "mdbreact";

import { Loader } from "../../../UI/Loaders/Loaders";
import {
  getLoansForDisbursement,
  setDisbursementFilterStartDate,
  setDisbursementFilterEndDate,
  setDisbursementPageCount,
  setDisbursementPageSize,
  setDisbursementPageNumber,
  removeDisbursedLoans,
  setDisbursementBorrowerName,
  setDisbursementLoanId,
  setDisbursementBvn,
  setDisbursementRcNumber,
  setDisbursementAggregatorId,
  setDisbursementLoanRef,
} from "../../../redux/actions/loans";
import { currencyFormatter } from "../../../helpers/stringControllers";
import Paginator from "../../Pagination/Pagination.component";
import { attachClassNameToTag } from "../../../helpers/attachClassNameToTag";
import Alert from "../../../NewComponents/TypedComponents/Alert/Alert.component";
import { formatMoney } from "../../../helpers/formatter";
import { RootState } from "../../../redux/store";
import { MixPanel } from "../../../shared/MixPanel/MixPanel";

type TState = {
  checkStatus: string;
  loansForDisbursement: any[];
  totalAmount: number;
  disbursementError: string | boolean;
  disbursementSuccess: string | boolean;
};

const PendingDisbursements = (props: any) => {
  // Component State
  const [state, setState] = useState<TState>({
    // loading: false,
    checkStatus: "off",
    loansForDisbursement: [],
    totalAmount: 0,
    disbursementError: "",
    disbursementSuccess: "",
  });

  const dispatch = useDispatch();
  const { disbursements: data } = useSelector(
    (state: RootState) => state?.loansReducer,
  );

  useEffect(() => {
    // prettier-ignore
    MixPanel.track_pageview({ "page": "Pending Disbursements" });
  }, []);

  let {
    records,
    loading,
    error,
    endDate,
    startDate,
    disbursementSize,
    disbursementPageNumber,
    disbursementCount,
    borrowerName,
    loanId,
    bvn,
    rcNumber,
    aggregatorId,
    loanRef,
    refetch,
  } = data;

  // Life Cycle Hooks
  useEffect(() => {
    dispatch(
      getLoansForDisbursement({
        page: disbursementPageNumber,
        size: disbursementSize,
        endDate,
        startDate,
        // disbursementCount,
        borrowerName,
        loanId,
        bvn,
        rcNumber,
        aggregatorId,
        loanRef,
      }),
    );

    // eslint-disable-next-line
  }, [disbursementPageNumber, disbursementSize, refetch]);

  const resetLoansForDisbursement = () => {
    setState({
      ...state,
      loansForDisbursement: [],
      totalAmount: 0,
      checkStatus: "off",
    });
  };

  // Disburse Selected Loans
  const disburseSelectedLoans = (e: any) => {
    e.preventDefault();
    const loansForDisbursement = state.loansForDisbursement;
    if (loansForDisbursement.length === 0)
      return dispatch(setToast("warning", "You have not selected any loans"));
    dispatch(
      showModal({
        modalIdentifier: "Loan",
        dataId: e.target.dataset["ref"],
        action: "disburse",
        subModal: "multiple",
        loans: state.loansForDisbursement,
        totalAmount: state.totalAmount,
        result: removeDisbursedLoans,
        resetLoansForDisbursement,
      }),
    );
  };

  // Multiple Checkbox
  const checkAllLoansForDisbursement = (e: any) => {
    e.preventDefault();
    if (e.target.dataset["state"] === "checked") {
      const allLoans = document.querySelectorAll(
        ".loans_for_disbursement",
      ) as any;
      for (let i = 0; i < allLoans.length; i++) {
        allLoans[i].checked = false;
      }

      e.target.dataset["state"] = "unchecked";

      setState({
        ...state,
        checkStatus: "off",
        totalAmount: 0,
        loansForDisbursement: [],
      });

      return;
    }

    let totalAmount = 0;
    let loans = [];
    const allLoans = document.querySelectorAll(
      ".loans_for_disbursement",
    ) as any;
    for (let i = 0; i < allLoans.length; i++) {
      allLoans[i].checked = true;
      const loanId = allLoans[i].dataset["ref"];
      loans.push(loanId);

      totalAmount += parseFloat(
        JSON.parse(allLoans[i].dataset["record"]).loan_amount,
      );
    }

    e.target.dataset["state"] = "checked";

    setState({
      ...state,
      checkStatus: "on",
      totalAmount,
      loansForDisbursement: [...state.loansForDisbursement, ...loans],
    });
  };

  // Single Checkbox
  const checkboxHandler = ({
    dataRecord: { loan_amount },
    eventTarget,
  }: any) => {
    const loanId = eventTarget.dataset["ref"];

    if (eventTarget.checked === false) {
      const loansForDisbursement = state.loansForDisbursement;
      const newLoansForDisbursement = loansForDisbursement.filter((loan) => {
        return loan !== loanId;
      });

      return setState({
        ...state,
        loansForDisbursement: [...newLoansForDisbursement],
        totalAmount: state.totalAmount - parseFloat(loan_amount),
      });
    }

    setState({
      ...state,
      loansForDisbursement: [...state.loansForDisbursement, loanId],
      totalAmount: state.totalAmount + parseFloat(loan_amount),
    });
  };

  // Open Modal
  const modalHandler = (
    e: any,
    tableName: any,
    modalRow: any,
    action: any,
    type?: any,
    subModal?: any,
    loans?: any,
  ) => {
    dispatch(
      showModal({
        modalIdentifier: tableName,
        dataRecord: modalRow,
        eventTarget: e.target,
        action: action,
        type,
        subModal: subModal,
        loans: loans,
        notification: setDisbursementNotification,
        result: removeDisbursedLoans,
        resetLoansForDisbursement,
      }),
    );
  };
  const resetTable = () => {
    dispatch(setDisbursementPageCount(null));
    dispatch(setDisbursementPageNumber(1));
  };
  const resetSearch = (e: any) => {
    e.preventDefault();
    dispatch(setDisbursementPageCount(null));
    dispatch(setDisbursementPageNumber(1));
    dispatch(setDisbursementPageSize(50));
    dispatch(setDisbursementBorrowerName(""));
    dispatch(setDisbursementLoanId(null));
    dispatch(setDisbursementBvn(""));
    dispatch(setDisbursementRcNumber(""));
    dispatch(setDisbursementAggregatorId(null));
    dispatch(setDisbursementLoanRef(""));
    dispatch(setDisbursementFilterStartDate(""));
    dispatch(setDisbursementFilterEndDate(""));
    dispatch(
      getLoansForDisbursement({
        page: 1,
        size: 50,
        aggregatorId: 0,
        borrowerName: "",
        bvn: "",
        endDate: "",
        startDate: "",
        loanId: "",
        loanRef: "",
        rcNumber: "",
      }),
    );
  };
  const getPendingDisbursementRecordsModal = () => {
    dispatch(
      showModal({
        modalIdentifier: "loan",
        dataRecord: [],
        action: "getpendingdisbursementrecords",
      }),
    );
  };

  const handlePaginationChange = (pageNumber: any) => {
    dispatch(setDisbursementPageNumber(pageNumber));
    getLoansForDisbursement({
      endDate,
      startDate,
      page: pageNumber,
      size: disbursementSize,
      borrowerName,
      loanId,
      bvn,
      rcNumber,
      aggregatorId,
      loanRef,
    });
  };
  // Set Disbursement Notification
  const setDisbursementNotification = (type: any, message: any) => {
    if (type === "success") {
      setState({
        ...state,
        disbursementSuccess: message,
        disbursementError: false,
      });
    } else if (type === "error") {
      setState({
        ...state,
        disbursementError: message,
        disbursementSuccess: false,
      });
    }
  };
  let RenderedComponent = null;
  //  If loans object is equl to null e.g. Initial component render before API fetch
  if (error) {
    RenderedComponent = (
      <div className="center-container">
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt">
              <i className="fas fa-bell" /> Unable to Fetch Loans
            </h4>
          </div>
        </div>
      </div>
    );
  } else if (loading) {
    RenderedComponent = (
      <Loader
        text="Loading available Loans for Disbursement ..."
        centered={true}
      />
    );
  } else if (data) {
    // Verify that a loans object exists
    if (data.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are Currently No Loans to
              Disburse
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <>
          <MDBTable
            responsive
            minimal="true"
            striped
            bordered
            hover
            id="table-to-xls"
          >
            <MDBTableHead>
              <tr>
                <th></th>
                <th>MANAGE</th>
                <th>AGGREGATOR NAME</th>
                <th>FIRST NAME</th>
                <th>LAST NAME</th>
                <th>BORROWER TYPE</th>
                <th>LOAN STATUS</th>
                <th>PRODUCT NAME</th>
                <th>LOAN AMOUNT</th>
                <th>LOAN INTEREST</th>
                <th>LOAN INTEREST RATE (%)</th>
                <th>LOAN REF</th>
                <th>CREATED DATE</th>
                <th>TRUST SCORE</th>
                <th>CONSOLIDATED SCORE DECISION</th>
                <th>SUPERFLUID SCORE</th>
                <th>SUPERFLUID STATUS</th>
                <th>ROLLOVER STATUS</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {data &&
                records.map((data: any, _id: any) => {
                  const {
                    loan_id,
                    borrower_details,
                    aggregator_details,
                    loan_amount,
                    loan_ref,
                    product_name,
                    pub_date,
                    status,
                    trust_score,
                    sf_score,
                    sf_status,
                    disbursement_decision,
                    is_rollover,
                    loan_interest,
                    loan_interest_rate,
                    product_detail,
                  } = data;
                  return (
                    <tr key={loan_id}>
                      <td>
                        <input
                          type="checkbox"
                          data-record={JSON.stringify(data)}
                          data-id={loan_id}
                          data-ref={loan_ref}
                          defaultChecked={state.loansForDisbursement.includes(
                            loan_ref,
                          )}
                          onClick={(e) =>
                            checkboxHandler({
                              dataRecord: data,
                              eventTarget: e.target,
                            })
                          }
                          className={`Loan loans_for_disbursement`}
                        />
                      </td>
                      <td>
                        <div className="dropdown">
                          <button
                            className="btn zoom-element btn-slim dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          ></button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <span
                              data-id={loan_id}
                              data-table={"Loan"}
                              data-action={"details"}
                              className="Loan cursor-pointer dropdown-item"
                              onClick={(e) => {
                                modalHandler(
                                  e,
                                  "Loan",
                                  data,
                                  "details",
                                  "",
                                  "single",
                                  [`${loan_id}`],
                                );
                              }}
                            >
                              DETAILS
                            </span>
                            <span
                              data-id={loan_id}
                              data-table={"Loan"}
                              data-action={"disburse"}
                              className="Loan cursor-pointer dropdown-item"
                              onClick={(e) => {
                                modalHandler(
                                  e,
                                  "Loan",
                                  data,
                                  "disburse",
                                  "",
                                  "single",
                                  [`${loan_id}`],
                                );
                              }}
                            >
                              DISBURSE
                            </span>
                            <span
                              data-id={loan_id}
                              data-table={"Loan"}
                              data-action={"deny"}
                              className="Loan cursor-pointer dropdown-item"
                              onClick={(e) => {
                                modalHandler(
                                  e,
                                  "Loan",
                                  data,
                                  "deny",
                                  "",
                                  "single",
                                  [`${loan_id}`],
                                );
                              }}
                            >
                              DENY
                            </span>
                            <span
                              data-id={loan_id}
                              data-table={"Loan"}
                              data-action={"deny"}
                              className="Loan cursor-pointer dropdown-item"
                              onClick={() =>
                                props.history.push({
                                  pathname: `/manage-loans/${loan_id}/deal-slip`,
                                  state: {
                                    aggregatorName:
                                      aggregator_details?.biz_name,
                                  },
                                })
                              }
                            >
                              DEAL SLIP
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>{aggregator_details.biz_name}</td>
                      <td>{borrower_details.first_name}</td>
                      <td>{borrower_details.last_name}</td>
                      <td>{borrower_details.borrower_type_value}</td>
                      <td>
                        <span className={attachClassNameToTag(status)}>
                          {attachClassNameToTag(status)}
                        </span>
                      </td>

                      <td>{product_detail?.product_name}</td>
                      <td>
                        {currencyFormatter(
                          Number(loan_amount),
                          aggregator_details?.currency,
                        )}
                      </td>
                      <td>
                        {currencyFormatter(
                          Number(loan_interest),
                          aggregator_details?.currency,
                        )}
                      </td>
                      <td>{loan_interest_rate}</td>
                      <td>{loan_ref}</td>
                      <td>{pub_date && pub_date.substring(0, 10)}</td>
                      <td>{trust_score}</td>
                      <td>{disbursement_decision}</td>
                      <td>{sf_score}</td>
                      <td>{sf_status}</td>
                      <td>{is_rollover ? "True" : "False"}</td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>

          <Paginator
            size={disbursementSize}
            page={disbursementPageNumber}
            count={disbursementCount}
            changeCurrentPage={(disbursementPageNumber: any) =>
              handlePaginationChange(disbursementPageNumber)
            }
          />
        </>
      );
    }
  }

  return (
    <Fragment>
      {state.disbursementError && (
        <Alert message={state.disbursementError as string} />
      )}
      {state.disbursementSuccess && (
        <Alert message={state.disbursementSuccess as string} type="success" />
      )}

      <div className="dataTables_wrapper">
        <div
          id="activeEcosystem"
          className="Disbursement table-view animated speed-1x fadeInRight"
        >
          {/*Welcome Block*/}
          <div className="block-header">
            <div className="flex-row m-b-2 m-t-1">
              <h3 className="m-l-1 m-b-1 flex-row">
                <aside className="rounder ">1</aside>
                &nbsp; Disbursements
              </h3>

              <div className="flex-row m-b-1">
                <div className="m-r-1">
                  <div className="rounded-border flex-row">
                    <div
                      data-state="unchecked"
                      onClick={checkAllLoansForDisbursement}
                    >
                      <i
                        className={`adv-icon fas fa-toggle-${state.checkStatus} fa-2x`}
                      />
                    </div>
                    &nbsp; <span>Select all</span>
                  </div>
                </div>
                <div className="m-r-1 rounded-border">
                  Selected: {state.loansForDisbursement.length}
                </div>

                <div className="rounded-border">
                  Total Amount: {formatMoney(state.totalAmount)}
                </div>
              </div>

              <div className="m-b-1 flex-row">
                <button
                  id="create"
                  data-id="create-role"
                  data-action="create"
                  className="create btn advancly-btn mr-2"
                  onClick={disburseSelectedLoans}
                >
                  <i className="far fa-credit-card" /> &nbsp; Proceed With Bulk
                </button>
                <button
                  id="create"
                  data-id="create-role"
                  data-action="create"
                  className="create btn advancly-btn"
                  onClick={getPendingDisbursementRecordsModal}
                >
                  <i className="fas fa-download m-r-1" />
                  Download
                </button>
              </div>
            </div>
            <div className="d-flex flex-row flex-wrap justify-content-between m-b-2">
              <div className="d-flex flex-row flex-wrap justify-content-start m-l-1 m-t-1">
                <label htmlFor="table-fetch" className="form-group">
                  Number of results:{" "}
                  <select
                    onChange={(e) => {
                      resetTable();
                      dispatch(setDisbursementPageNumber(1));
                      dispatch(setDisbursementPageSize(e.target.value));
                    }}
                    id="table-fetch"
                    className="table-fetch-select m-l-1"
                    value={disbursementSize}
                  >
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={200}>200</option>
                  </select>
                </label>
              </div>

              <div className="flex-row">
                <button
                  id="create"
                  data-id="create-role"
                  data-action="create"
                  className="m-r-1 create btn advancly-white-btn"
                  onClick={(e) => {
                    modalHandler(e, "loan", "", "disbursementsearch");
                  }}
                >
                  <i className="fas fa-search" />
                  &nbsp; Advance Search
                </button>
                <button
                  id="create"
                  data-id="create-role"
                  data-action="create"
                  className="btn advancly-white-btn"
                  onClick={(e) => {
                    resetSearch(e);
                  }}
                >
                  <i className="fas fa-refresh" />
                </button>
              </div>
            </div>
          </div>

          {RenderedComponent}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const { disbursements } = state.loansReducer;

  return {
    disbursements,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    removeDisbursedLoans,
  })(PendingDisbursements),
);
