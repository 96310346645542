import React, { useEffect } from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
// UI Components
import { Loader } from "../../../../UI/Loaders/Loaders";
import { currencyFormatter } from "../../../../helpers/stringControllers";
import useAllAborrowersLoansRepayment from "../../../../custom-hooks/useAllABorrowersLoansRepayments";
import { Fragment } from "react";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";

const AllBorrowerLoansRepayments = ({ dataRecord }) => {
  const { data, status, error } = useAllAborrowersLoansRepayment({
    borrower_id: dataRecord.borrower_id,
  });

  useEffect(() => {
    // prettier-ignore
    MixPanel.track_pageview({ "page": "All Repayments" });
  }, []);

  //JSX Template
  let RenderedComponent = null;
  if (!data && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to Fetch Borrower's Loans
            Repayments
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader
        centered={true}
        text="Loading all borrower's loans repayments..."
      />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              borrower's loans repayments, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (data && data.data && data.data.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no loans that
              have repayments \for this borrower
            </h4>
          </div>
        </div>
      );
    } else {
      if (
        data &&
        data.data &&
        data.data.filter((loan) => loan.repayment_schedule.length).length
      ) {
        const newData = data.data.filter(
          (loan) => loan.repayment_schedule.length,
        );
        RenderedComponent = (
          <Fragment>
            {newData.map((data, id) => {
              const {
                loan_ref,
                loan_amount,
                loan_amount_repay,
                repayment_schedule,
              } = data;
              return (
                <div className="mb-5" key={id}>
                  <h5 className="font-weight-bold">
                    Loan Reference Number : {loan_ref}
                  </h5>
                  <h5 className="font-weight-bold">
                    Loan Amount : {currencyFormatter(loan_amount)}
                  </h5>
                  <h5 className="font-weight-bold">
                    Loan Amount to Repay :{" "}
                    {currencyFormatter(loan_amount_repay)}
                  </h5>
                  <h4 className="text-center mb-3 text-underline font-weight-bold">
                    Loan Repayment Schedule
                  </h4>

                  <MDBTable responsive minimal="true" bordered hover>
                    <MDBTableHead>
                      <tr>
                        <th>S/N</th>
                        <th>Repayment Date</th>
                        <th>Repayment Amount</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      <Fragment>
                        {repayment_schedule.map((repayment, _id) => {
                          const { pay_date, repay_amount } = repayment;
                          return (
                            <tr key={_id}>
                              <td className="center-text">{_id + 1}</td>
                              <td className="center-text">
                                {moment(pay_date).format("DD-MM-YYYY")}
                              </td>
                              <td className="center-text">
                                {currencyFormatter(Number(repay_amount))}
                              </td>
                            </tr>
                          );
                        })}
                      </Fragment>
                    </MDBTableBody>
                  </MDBTable>
                </div>
              );
            })}
          </Fragment>
        );
      } else {
        RenderedComponent = (
          <div>
            <h5 className="text-center">No Data available</h5>
          </div>
        );
      }
    }
  }

  return (
    <div className="table-view speed-1x fadeInRight">{RenderedComponent}</div>
  );
};

export default AllBorrowerLoansRepayments;
